import React from "react";
import { Box, Button } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "./CarAdvertisement2.css";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.css";
import { Link } from "react-router-dom";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));
function CarAdvertisement2(props) {
  return (
    <div id="CarAdvertisement2">
      <Box sx={{ my: 3 }}>
        <h3 className="section_title_cyan max_size_cyan">Add Advertisement</h3>
      </Box>
      <Box display="flex" sx={{ pb: 8 }} className="full_content_wrapper">
        <UserPanelAside></UserPanelAside>
        <Box flexGrow="1" sx={{ p: 6 }}>
          <form action="" className="form_auth">
            <div className="content_wrapper">
              <Box sx={{ p: 2 }}>
                <FormBox1></FormBox1>
                <FormBox2></FormBox2>
                <FormSubmit></FormSubmit>
              </Box>
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default CarAdvertisement2;

const FormBox1 = () => {
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Personal Details</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Title:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Title..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Link:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Link..."
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Price:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Price..."
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, textAlign: "center" }}>
              <h3
                className="btn_cyan_style max_size_cyan"
                style={{ display: "inline-block", padding: "4px 12px" }}
              >
                Please upload image of this particular height and width
                (798x360).
              </h3>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox2 = () => {
  const handleUploader = (event) => {
    // make a response to send it in database
    //console.log(event.target.value);
  };
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Vehicle Data</h2>
      </Box>{" "}
      <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Image:
                </label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  className="form_control"
                  placeholder="Year of Registration of Vehicle..."
                  id="Vehicle_rc_input"
                  onChange={handleUploader}
                />
                <div className="upload_wrapper">
                  <PictureUploader></PictureUploader>
                </div>
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormSubmit = () => {
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button type="submit" sx={{ px: 6 }} className="btn_cyan_style">
        SUBMIT
      </Button>
    </Box>
  );
};
