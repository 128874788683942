import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textField: {
    "& [class*=MuiInputBase-root-MuiInput-root]": {
      border: "none",
      display: "none",
    },
  },
});

function OverlapBox(props) {
  const [value, setValue] = React.useState(0);
  const ref = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(event.target);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  // for type label
  const optionsType = [
    { label: "All" },
    { label: "The Godfather" },
    { label: "The Godfather: Part II" },
    { label: "The Dark Knight" },
    { label: "12 Angry Men" },
    { label: "Schindler's List" },
    { label: "Pulp Fiction" },
  ];

  // for brand label
  const optionsBrand = [
    { label: "All" },
    { label: "The Godfather" },
    { label: "The Godfather: Part II" },
    { label: "The Dark Knight" },
    { label: "12 Angry Men" },
    { label: "Schindler's List" },
    { label: "Pulp Fiction" },
  ];

  // for model label
  const optionsModel = [
    { label: "All" },
    { label: "The Godfather" },
    { label: "The Godfather: Part II" },
    { label: "The Dark Knight" },
    { label: "12 Angry Men" },
    { label: "Schindler's List" },
    { label: "Pulp Fiction" },
  ];

  // for year label
  const optionsYear = [
    { label: "2010" },
    { label: "2011" },
    { label: "2012" },
    { label: "2013" },
    { label: "2014" },
    { label: "2015" },
    { label: "2016" },
    { label: "2017" },
    { label: "2018" },
    { label: "2019" },
    { label: "2020" },
    { label: "2021" },
    { label: "2022" },
    { label: "2023" },
    { label: "2024" },
    { label: "2025" },
  ];

  // for min label
  const optionsMin = [
    { label: "All" },
    { label: "The Godfather" },
    { label: "The Godfather: Part II" },
    { label: "The Dark Knight" },
    { label: "12 Angry Men" },
    { label: "Schindler's List" },
    { label: "Pulp Fiction" },
  ];

  // for max label
  const optionsMax = [
    { label: "All" },
    { label: "The Godfather" },
    { label: "The Godfather: Part II" },
    { label: "The Dark Knight" },
    { label: "12 Angry Men" },
    { label: "Schindler's List" },
    { label: "Pulp Fiction" },
  ];

  const classes = useStyles();

  // ......................input handles forms ................

  // for type options input
  const [optionsState, setOptionsState] = useState(optionsType[0].label);
  //console.log(optionsState)

  // for brand  input
  const [brandState, setBrandState] = useState(optionsBrand[0].label);
  //console.log(brandState)

  // for modelState  input
  const [modelState, setModelState] = useState(optionsModel[0].label);
  //console.log(modelState)

  // for year  input
  const [yearState, setYearState] = useState(optionsYear[0].label);
  //console.log(yearState)

  // for minPriceState  input
  const [minPriceState, setMinPriceState] = useState(optionsMin[0].label);
  //console.log(minPriceState)

  // for maxPriceState  input
  const [maxPriceState, setMaxPriceState] = useState(optionsMax[0].label);
  //console.log(maxPriceState)

  // ............form submit object .......

  const formSubmitObjec = {
    type: optionsState,
    brand: brandState,
    model: modelState,
    year: yearState,
    min_price: minPriceState,
    max_price: maxPriceState,
  };

  const searchHandle = () => {
    //console.log(formSubmitObjec)
  };

  return (
    <div ref={ref}>
      <Box className="overlap_box">
        <Typography variant="h5" align="center">
          FIND BEST CARS NEAR YOU
        </Typography>
        <Box align="center" display="flex" justifyContent="center">
          <Tabs
            value={value}
            onChange={handleChange}
            orientation="horizontal"
            centered
            className="overlap_tabs"
            variant="scrollable"
            scrollButtons="auto"
            // TabIndicatorProps={{
            //   style: { transition: "none" },
            // }}
          >
            <Tab
              disableRipple
              sx={{ color: "black", borderRadius: 8 }}
              label="All Car Types"
            />
            <Tab
              disableRipple
              sx={{ color: "black", borderRadius: 8 }}
              label="New Cars"
            />
            <Tab
              disableRipple
              sx={{ color: "black", borderRadius: 8 }}
              label="Used Cars"
            />
          </Tabs>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <form action="" method="post">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid
                item
                className="form_group_wrapper"
                xs={12}
                sm={6}
                sx={{ marginBottom: "20px" }}
              >
                <label htmlFor="">TYPE:</label>
                <Item className="form_mui_group" sx={{ boxShadow: "none" }}>
                  <Autocomplete
                    value={optionsState}
                    onChange={(event, newValue) => {
                      setOptionsState(newValue.label);
                    }}
                    className="form_mui_group_control"
                    defaultValue={optionsType[0].label}
                    options={optionsType}
                    renderInput={(params) => (
                      <TextField className={classes.textField} {...params} />
                    )}
                  />
                </Item>
              </Grid>
              <Grid
                item
                className="form_group_wrapper"
                xs={12}
                sm={6}
                sx={{ marginBottom: "20px" }}
              >
                <label htmlFor="">BRAND:</label>
                <Item className="form_mui_group" sx={{ boxShadow: "none" }}>
                  <Autocomplete
                    value={brandState}
                    onChange={(event, newValue) => {
                      setBrandState(newValue.label);
                    }}
                    className="form_mui_group_control"
                    defaultValue={optionsBrand[0].label}
                    options={optionsBrand}
                    renderInput={(params) => (
                      <TextField className={classes.textField} {...params} />
                    )}
                  />
                </Item>
              </Grid>
              <Grid
                item
                className="form_group_wrapper"
                xs={12}
                sm={6}
                sx={{ marginBottom: "20px" }}
              >
                <label htmlFor="">MODEL:</label>
                <Item className="form_mui_group" sx={{ boxShadow: "none" }}>
                  <Autocomplete
                    value={modelState}
                    onChange={(event, newValue) => {
                      setModelState(newValue.label);
                    }}
                    className="form_mui_group_control"
                    defaultValue={optionsModel[0].label}
                    options={optionsModel}
                    renderInput={(params) => (
                      <TextField className={classes.textField} {...params} />
                    )}
                  />
                </Item>
              </Grid>
              <Grid
                item
                className="form_group_wrapper"
                xs={12}
                sm={6}
                sx={{ marginBottom: "20px" }}
              >
                <label htmlFor="">YEAR:</label>
                <Item className="form_mui_group" sx={{ boxShadow: "none" }}>
                  <Autocomplete
                    value={yearState}
                    onChange={(event, newValue) => {
                      setYearState(newValue.label);
                    }}
                    className="form_mui_group_control"
                    defaultValue={optionsYear[0].label}
                    options={optionsYear}
                    renderInput={(params) => (
                      <TextField className={classes.textField} {...params} />
                    )}
                  />
                </Item>
              </Grid>
              <Grid
                item
                className="form_group_wrapper"
                xs={12}
                sm={6}
                sx={{ marginBottom: "20px" }}
              >
                <label htmlFor="">MIN PRICE:</label>
                <Item className="form_mui_group" sx={{ boxShadow: "none" }}>
                  <Autocomplete
                    value={minPriceState}
                    onChange={(event, newValue) => {
                      setMinPriceState(newValue.label);
                    }}
                    className="form_mui_group_control"
                    defaultValue={optionsMin[0].label}
                    options={optionsMin}
                    renderInput={(params) => (
                      <TextField className={classes.textField} {...params} />
                    )}
                  />
                </Item>
              </Grid>

              <Grid
                item
                className="form_group_wrapper"
                xs={12}
                sm={6}
                sx={{ marginBottom: "20px" }}
              >
                <label htmlFor="">MAX PRICE:</label>
                <Item className="form_mui_group" sx={{ boxShadow: "none" }}>
                  <Autocomplete
                    value={maxPriceState}
                    onChange={(event, newValue) => {
                      setMaxPriceState(newValue.label);
                    }}
                    className="form_mui_group_control"
                    defaultValue={optionsMax[0].label}
                    options={optionsMax}
                    renderInput={(params) => (
                      <TextField className={classes.textField} {...params} />
                    )}
                  />
                </Item>
              </Grid>

              <Grid
                item
                className="form_group_wrapper"
                xs={12}
                sm={6}
                sx={{ margin: "auto" }}
              >
                <Item
                  sx={{
                    padding: "0px",
                    margin: "0px",
                    width: "100%",
                    background: "transparent",
                  }}
                >
                  <Button className="btn_search" onClick={searchHandle}>
                    SEARCH
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default OverlapBox;
