import React, { useState } from "react";
import "../../static/css/forms_style/forms_style.scss";
import "./Login.scss";
import { Button, Box } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import { Link, useNavigate } from "react-router-dom";
import { userLoginHttpRequest } from "../../static/http-content";
import { LoginUser } from "../../Adapters/Auth";
import { toast } from "react-toastify";
import { FormattedMessage } from 'react-intl';
import LoginWithGoogle from "../../Components/SocialsLogin/LoginWithGoogle";
import LoginWithFacebook from "../../Components/SocialsLogin/LoginWithFacebook";
import { getLoginuserToken } from "../../Redux/Action/loginaction";
import { useIntl } from 'react-intl';
import { connect } from "react-redux";
function Login(props) {
  const [loginData, setLoginData] = useState({
    email: null,
    password: null,
  });
  const[aceestoken,setaccessToken]=useState();
  const intl = useIntl();
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();

  const handleLogin = (data) => {
    
    let toast_id = toast.loading('Please wait...')
    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    LoginUser(loginData,(sucess)=>{
      if(sucess)
      {
        
        toast.update(
          toast_id,
          {
            render : 'LoggedIn Successful',
            type : 'success',
            isLoading : false,
            closeOnClick : true,
            autoClose : 5000,
            pauseOnHover : true,
          }
        )
        localStorage.setItem("token", sucess?.data?.data?.token?.access_token);
        localStorage.setItem("refresh_token",sucess?.data?.data?.token?.access_token);
        props.tokenData(sucess?.data?.data?.token?.access_token);
        localStorage.setItem("userId", sucess?.data?.data?.id);
        localStorage.setItem("userEmail",sucess?.data?.data?.email);
        let userEmail = localStorage.getItem('userEmail')
        localStorage.removeItem('userEmail')
        navigate("/user_panel");
      }

    },(message) => {
      
      toast.update(
        toast_id,
        {
          render : message,
          type : 'error',
          isLoading : false,
          closeOnClick : true,
          autoClose : 5000,
          pauseOnHover : true,
        }
      )
    })
  }

  const submitLoginData = (event) => {
    event.preventDefault();
    handleLogin(loginData)

  };
  return (
    <div id="Login">
      <h2 className="form_name"><FormattedMessage id="login"/></h2>
      <div className="content_wrapper form_wrapper">
        <h3 className="form_inner_name"><FormattedMessage id="login-to-your-account"/></h3>
        <Box sx={{ mb: 2 }}>
          <div className="form_social_auth title_line">
            <LoginWithGoogle
              is_agreed_terms_condition={true}
              onVerify={(data) => {
                handleLogin(data)
              }}
            >
              <Button sx={{ p: 1 }} className="form_google_auth">
                {" "}
                <GoogleIcon></GoogleIcon> <FormattedMessage id="sign-in-google"/>
              </Button>
            </LoginWithGoogle>
            <LoginWithFacebook is_agreed_terms_condition={true} onVerify={(data) => {
                handleLogin(data)
              }}>
              <Button sx={{ p: 1 }} className="form_facebook_auth">
                {" "}
                <FacebookIcon></FacebookIcon>  <FormattedMessage id="sign-in-Facebook"/>
              </Button>
            </LoginWithFacebook>
          </div>
        </Box>
        <form
          // action=""
          className="form_auth"
          // method="post"
          onSubmit={submitLoginData}
        >
          <Box sx={{ mb: 2, mt: 3 }}>
            <div className="form_group">
              <label htmlFor="" className="form_label">
              <FormattedMessage id="email"/>:{" "}
              </label>
              <input
                type="email"
                required
                className="form_control"
                placeholder={intl.formatMessage({ id: "enter-email" })}
                 value={loginData.email}
                onChange={(event) =>
                  setLoginData({
                    ...loginData,
                    email: event.target.value,
                  })
                }
              />
            </div>
          </Box>
          <Box sx={{ mb: 2 }}>
            <div className="form_group">
              <label htmlFor="" className="form_label">
              <FormattedMessage id="password"/>
              </label>
              <input
                type={showPass ? "text" : "password"}
                required
                className="form_control"
                placeholder={intl.formatMessage({ id: "enter-password" })}
                value={loginData.password}
                onChange={(event) =>
                  setLoginData({
                    ...loginData,
                    password: event.target.value,
                  })
                }
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  type="button"
                  style={{
                    background: "transparent",
                    color: "#fff",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowPass(!showPass);
                  }}
                >
                  {showPass ? <FormattedMessage id="hide-password"/> :  <FormattedMessage id="show-password"/>}
                </button>
              </div>
            </div>
          </Box>
          <Box sx={{ mb: 2, textAlign: "center" }}>
            <Link className="title_line line_decoration" to="/recover_password">
            <FormattedMessage id="forget-password ?"/>
            </Link>
          </Box>
          <Box sx={{ mb: 2 }}>
            <input
              className="submit_btn"
              style={{ padding: "1rem" }}
              type="submit"
              value={intl.formatMessage({ id: "sign-in" })}
            />
            {/* Sign in
            </Button> */}
          </Box>
          <Box sx={{ textAlign: "center", mb: 2 }}>
          <FormattedMessage id="new-user ?"/>
            <Link className="title_line line_decoration" to="/register">
            <FormattedMessage id="register"/>
            </Link>
          </Box>
        </form>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  info: state.rootReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    tokenData: (data) => dispatch(getLoginuserToken(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);

