import React, { useEffect, useState } from "react";
 import "./Forgetpassword.scss";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, Stack, useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PictureUploadProfile from "../../Components/PictureUploadProfile/PictureUploadProfile";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DatePicker from "react-datepicker";
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import CountryDropdown from "country-dropdown-with-flags-for-react";

import PhoneInput from "react-phone-number-input";
import {
  changePasswordHttpRequest,
  createProfileHttpRequest,
  updateProfileHttpRequest,
  userDetailHttpRequest,
} from "../../static/http-content";
import moment from "moment";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));

function Forgetpassword(props) {
  const navigate = useNavigate();
  const intl = useIntl();
  const [userData, setUserData] = useState({
    first_name: null,
    last_name: null,
    phone: null,
    whatsapp_no: null,
    dob: null,
    country: null,
    state: null,
    road_no: null,
    house_no: null,
    biography: null,
  });

  const [password, setPassword] = useState({
    current_password: null,
    new_password: null,
    confirm_new_password: null,
  });

  const [email, setEmail] = useState(null);
  const [profile_pic, setProfile_pic] = useState({
    file: null,
    file_url: null,
  });
  const [profilePicLoading, setProfilePicLoading] = useState(false);
  const [profile_created, setProfile_created] = useState(false);


  useEffect(() => {
    
  }, []);

 



 

 

  const submitPassword = () => {
    console.log(password);

    if (password.new_password === password.confirm_new_password) {
      let formData = new FormData();
      formData.append("current_password", password.current_password);
      formData.append("new_password", password.new_password);
      formData.append("confirm_new_password",password.confirm_new_password)
      changePasswordHttpRequest
        .post("/", formData, {
          headers: { Authorization: `jwt ${localStorage.getItem("refresh_token")}` },
        })
        .then((res) => {
          if(res?.status === 200)
          {

            alert(res?.data.message);
            navigate("/login")
          }

          
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      alert("Confirm password is wrong");
    }
  };

  return (
    <div id="EditProfile">
      <Box sx={{ pt: 6 }}>
        <h3 className="section_title_cyan max_size_cyan"><FormattedMessage id="Change-Password"/></h3>

        <Box id="section1" component="section" sx={{ mt: 6 }}>
          <form action="" className="form_auth">
            <div className="content_wrapper">
           
          
              <FormBox2 password={password} setPassword={setPassword} />
              <FormSubmitPassword submitPassword={submitPassword} />
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default Forgetpassword;



const FormBox2 = ({ password, setPassword }) => {
  const matches = useMediaQuery("(min-width:640px)");
  const intl = useIntl();
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line"><FormattedMessage id="Change-Password"/></h2>
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Current-Password"/>:
                </label>
                <input
                  type="password"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "Current-Password" })}
                  value={password.current_password}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      current_password: e.target.value,
                    })
                  }
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="New-Password"/>:
                </label>
                <input
                  type="password"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "New-Password" })}
                  value={password.new_password}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      new_password: e.target.value,
                    })
                  }
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Confirm-Password"/>:
                </label>
                <input
                  type="password"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "Confirm-Password" })}
                  value={password.confirm_new_password}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      confirm_new_password: e.target.value,
                    })
                  }
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};


const FormSubmitPassword = (props) => {

  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button
        sx={{ px: 6 }}
        className="btn_cyan_style"
        onClick={props.submitPassword}
      >
      <FormattedMessage id="UPDATE-PASSWORD"/>
      </Button>
    </Box>
  );
};


