import React from "react";
import { Box } from "@mui/material";
import "../../static/css/style_helper/style_helper.scss";
function PrivacyPolicy(props) {
  return (
    <div id="PrivacyPolicy">
      <Section1></Section1>
    </div>
  );
}

export default PrivacyPolicy;

function Section1() {
  return (
    <section
      id="section1"
      className="bg_img_section"
      style={{
        "--bg-section-img": `url(${require("../../static/img/35cf026bb0dded565441fed7aee42ae0.jpg")})`,
      }}
    >
      <Box sx={{ py: 6 }}>
        <h3 className="section_title_cyan max_size_cyan">PrivacyPolicy</h3>
        <div className="section_wrapper section_space_wrapper">
          <Box sx={{ pt: 5 }}>
            <h3 className="title_line section_title_lg">PrivacyPolicy</h3>
            <p className="paragraph_space1">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi
              corrupti cum, omnis deserunt rem natus unde libero distinctio
              dicta odit assumenda quod optio eveniet nesciunt deleniti.
              Recusandae perspiciatis, id molestiae, nobis, porro quasi vel
              autem non fugiat incidunt accusamus! Sed quidem repudiandae,
              <br />
              <br />
              praesentium, iste voluptas mollitia recusandae impedit voluptatem
              libero dolorem tenetur, omnis voluptatum et? Dolore explicabo
              suscipit harum ipsum quisquam dignissimos accusamus laboriosam
              adipisci, vel cumque voluptas, soluta ex nisi aperiam dolorem odit
              obcaecati accusantium saepe, quod beatae. Aliquid voluptate
              aspernatur consectetur quas harum modi ex eum dolorem neque
              veritatis eius dignissimos, perferendis ullam ipsa, magni iste
              maiores ab.
            </p>
          </Box>
        </div>
      </Box>
    </section>
  );
}
