import React, { useEffect, useState } from "react";
import "./SellCar2.scss";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Tab, Tabs, TextField, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DropzoneArea, DropzoneAreaBase } from "material-ui-dropzone";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));

function SellCar2() {
  let location = useLocation();
  const [packageData, setPackageData] = useState({});
  const [vehicleData, setVehicleData] = useState({
    type: "New",
  });
  useEffect(() => {
    console.log(location.state);
    setPackageData(location.state);
  }, []);

  return (
    <div id="SellCar2">
      <Box sx={{ pt: 6 }}>
        <h3 className="section_title_cyan max_size_cyan">Sell Vehicle</h3>

        <Box id="section1" component="section" sx={{ mt: 6 }}>
          <form action="" className="form_auth">
            <div className="content_wrapper">
              <FormBox1
                vehicleData={vehicleData}
                setVehicleData={setVehicleData}
              ></FormBox1>
              <FormBox2
                vehicleData={vehicleData}
                setVehicleData={setVehicleData}
              ></FormBox2>
              <FormBox3
                vehicleData={vehicleData}
                setVehicleData={setVehicleData}
              ></FormBox3>
              <FormBox4
                vehicleData={vehicleData}
                setVehicleData={setVehicleData}
              ></FormBox4>
              <FormBox5
                vehicleData={vehicleData}
                setVehicleData={setVehicleData}
              ></FormBox5>
              <FormBox6
                vehicleData={vehicleData}
                setVehicleData={setVehicleData}
              ></FormBox6>
              <FormSubmit></FormSubmit>
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default SellCar2;

const FormBox1 = (props) => {
  const matches = useMediaQuery("(min-width:640px)");
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Vehicle Basic Information</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Title:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Title..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 2, mt: matches ? 3 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Vehicle Condition:{" "}
                </label>
                <Box align="center" display="flex" justifyContent="center">
                  <Tabs
                    value={props.vehicleData.type}
                    onChange={(event, newValue) =>
                      props.setVehicleData({
                        ...props.vehicleData,
                        type: newValue,
                      })
                    }
                    centered
                    className="overlap_tabs"
                  >
                    <Tab
                      sx={{ flex: 1, textTransform: "none", color: "black" }}
                      disableRipple
                      label="Used"
                      value="Used"
                    />
                    <Tab
                      sx={{ flex: 1, textTransform: "none", color: "black" }}
                      disableRipple
                      label="New"
                      value="New"
                    />
                  </Tabs>
                </Box>
              </div>
            </Box>
          </Item>
        </Grid> */}
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Vehicle Brand:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="First Name..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Vehicle Model:{" "}
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Last Name..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Vehicle Model Year:
                </label>
                <div className="form_mui_group">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      views={["year"]}
                      disableFuture
                      value={props.vehicleData.model_year}
                      onChange={(newValue) => {
                        props.setVehicleData({
                          ...props.vehicleData,
                          model_year: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form_mui_datepicker"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Vehicle Condition:{" "}
                </label>
                <Box align="center" display="flex" justifyContent="center">
                  <Tabs
                    value={props.vehicleData.type}
                    onChange={(event, newValue) =>
                      props.setVehicleData({
                        ...props.vehicleData,
                        type: newValue,
                      })
                    }
                    centered
                    className="overlap_tabs"
                  >
                    <Tab
                      sx={{ flex: 1, textTransform: "none", color: "black" }}
                      disableRipple
                      label="Used"
                      value="Used"
                    />
                    <Tab
                      sx={{ flex: 1, textTransform: "none", color: "black" }}
                      disableRipple
                      label="New"
                      value="New"
                    />
                  </Tabs>
                </Box>
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox2 = (props) => {
  const handleUploader = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      console.log(img);
      props.setVehicleData({
        ...props.vehicleData,
        registration_image: event.target.files[0],
        registration_image_url: URL.createObjectURL(img),
      });
    }
  };
  const matches = useMediaQuery("(min-width:640px)");
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Vehicle Data</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid
          item
          xs={12}
          md={6}
          style={matches ? null : { display: "flex", justifyContent: "center" }}
        >
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 2, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Vehicle Registration Card:
                </label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  className="form_control"
                  placeholder="Year of Registration of Vehicle..."
                  id="Vehicle_rc_input"
                  onChange={handleUploader}
                />
                <PictureUploader
                  registration_image={props.vehicleData.registration_image_url}
                ></PictureUploader>
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 2, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Date of Registration of Vehicle:
                </label>
                <div className="form_mui_group">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      // views={["year"]}
                      disableFuture
                      value={props.vehicleData.registration_date}
                      onChange={(newValue) => {
                        props.setVehicleData({
                          ...props.vehicleData,
                          registration_date: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form_mui_datepicker"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </Box>

            {/* <Box sx={{ mb: 2, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Month of Registration of Vehicle:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Month of Registration of Vehicle..."
                />
              </div>
            </Box>

            <Box sx={{ mb: 2, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Vehicle No:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Vehicle No..."
                />
              </div>
            </Box> */}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox3 = (props) => {
  const handleUploader = (files) => {
    console.log(files);
  };
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Upload Pictures</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Item
            sx={{
              boxShadow: 0,
            }}
            className="Upload_picture_dropzone_wrapper"
          >
            <DropzoneArea
              acceptedFiles={["image/*"]}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={handleUploader}
              filesLimit={20}
            />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox4 = () => {
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Listing Options</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Weight:
                </label>
                <input
                  type="number"
                  inputMode="numeric"
                  className="form_control"
                  placeholder="KG"
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  No of Seats
                </label>
                <input
                  type="number"
                  inputMode="numeric"
                  className="form_control"
                  placeholder="Seats"
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Characteristics:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Characteristics..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Environment Energy:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Environment Energy..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Engine and Drive:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Engine and drive"
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Warranty:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Years"
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Services:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Services..."
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Details:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Details..."
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Origin Country:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Origin Country"
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Shipping Country:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Shipping Country"
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
const FormBox5 = () => {
  const matches = useMediaQuery("(min-width:640px)");
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Other Information</h2>
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Country:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Choose Country"
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  State:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Choose State"
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Price:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="CHF"
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox6 = () => {
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Sell Online</h2>
      </Box>
    </Box>
  );
};

const FormSubmit = () => {
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button type="submit" sx={{ px: 6 }} className="btn_cyan_style">
        SUBMIT
      </Button>
    </Box>
  );
};
