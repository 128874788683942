import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
function OverlapBox2(props) {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const listObject = [
    {
      id: 1,
      title: "BUY A CAR",
      desc: "Browse through 100+ cars",
      link: "",
    },
    {
      id: 2,
      title: "SELL A CAR",
      desc: "Sell your car instantly",
      link: "",
    },
    {
      id: 3,
      title: "LOOK FOR DEALER",
      desc: "check dealers near you",
      link: "",
    },
    {
      id: 4,
      title: "LOOK FOR GARAGE",
      desc: "buy or rent garage near you",
      link: "",
    },
  ];
  return (
    <div id="overlay_box2">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 3, md: 4 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          {listObject.map((v, i) => {
            return (
              <Grid item xs={6} md={3} key={i} style={{ height: "auto" }}>
                <Item
                  className="overlay_item"
                  sx={{ height: "100%", borderRadius: 5, boxShadow: 4, p: 2 }}
                >
                  <Box
                    component={Link}
                    to={v.link}
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography variant="h5" sx={{ mb: 1 }}>
                      {v.title}
                    </Typography>
                    <Typography variant="h6">{v.desc}</Typography>
                  </Box>
                </Item>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
}

export default OverlapBox2;
