import React, { useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import "./MerchantDetails.scss";
import "../../static/css/style_helper/style_helper.scss";
import { ProductMaterialCard3 } from "../../Components/ProductMaterialCard/ProductMaterialCard";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function MerchantDetails(props) {
  const cardObject = [
    {
      id: 1,
      img: require("../../static/img/lance-asper-N9Pf2J656aQ-unsplash.jpg"),
      title: "MUSTANG GT",
      price: "17000.00 CHF",
      link: "#",
      postal_code: "5017",
      automatic: (
        <>
          <img
            style={{ height: "12px" }}
            src={require("../../static/icons/highway-icon.png")}
          />{" "}
          169KM
        </>
      ),
      used: "9/2015",
    },
    {
      id: 2,
      img: require("../../static/img/benjamin-child-7Cdw956mZ4w-unsplash.jpg"),
      title: "BMW M3",
      price: "17000.00 CHF",
      link: "#",
      postal_code: "5017",
      automatic: (
        <>
          <img
            style={{ height: "12px" }}
            src={require("../../static/icons/highway-icon.png")}
          />{" "}
          169KM
        </>
      ),
      used: "9/2015",
    },
  ];
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div id="MerchantDetails">
      <Box component="section" id="section1" sx={{ width: "100%" }}>
        <Box sx={{ mb: 2, mt: matches ? 0 : 2 }}>
          <h3
            className="section_title_cyan max_size_cyan"
            style={{ width: matches ? "50%" : "70%" }}
          >
            Merchant Details
          </h3>
        </Box>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
          <Grid item xs={12} md={7}>
            <Item sx={{ boxShadow: 0, height: "100%", pb: 0, px: 0 }}>
              <img
                width="100%"
                height="100%"
                style={{ objectFit: "cover", borderRadius: "20px" }}
                src={require("../../static/img/1623152459_merchant.png")}
                alt="img"
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={5}>
            <Item
              sx={{
                boxShadow: 0,
                textAlign: "left",
                p: matches ? null : 0,
                pb: 0,
                pt: matches ? null : 2,
              }}
            >
              <ProductDetails></ProductDetails>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Box component="section" id="section2">
        <Box>
          <h2
            className="title_line section_title"
            style={{ display: "inline-block" }}
          >
            2 Vehicles
          </h2>

          <Box>
            <ProductMaterialCard3
              cardObject={cardObject}
            ></ProductMaterialCard3>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default MerchantDetails;

function ProductDetails() {
  const product_address_object = [
    {
      id: 1,
      name: "Address:",
      info: "80989, Densburen, AG, CH, 5026",
    },
    {
      id: 2,
      name: "Email:",
      info: "banwari.hawkscode@gmail.com",
    },
    {
      id: 3,
      name: "Phone:",
      info: (
        <>
          8058191887 <Button className="calling_btn ">Call</Button>
        </>
      ),
    },
  ];

  // input fields

  const [phoneNumber, setPhoneNumber] = useState();
  return (
    <div id="productDetails">
      <Box className="product_title title_line" sx={{ m: 0, p: 0 }}>
        <h2 data-before-text="Kanton Aargau" data-after-text="Barmelweid 5017">
          KUMAWAT BANWARI
        </h2>
      </Box>

      <Box className="product_address" sx={{ mt: 4, mb: 2 }}>
        <Button className="buy_btn ">Call Now</Button>
      </Box>

      <Box>
        <h3 className="title_line">Other Information</h3>
      </Box>

      <Box sx={{ mt: 3, mb: 1 }}>
        <span>newtesxtnewret:</span>
        <span style={{ marginLeft: "25px" }}>Testing</span>
      </Box>
      <div className="title_line" style={{ marginBottom: "35px" }}></div>

      <Box className="product_forms">
        <h3>ENQUIRY</h3>
        <div className="form_group">
          <label htmlFor="" className="form_label">
            Name:
          </label>
          <input type="text" className="form_control" placeholder="Name..." />
        </div>

        <div className="form_group">
          <label htmlFor="" className="form_label">
            Email:
          </label>
          <input type="email" className="form_control" placeholder="Email..." />
        </div>

        <div className="form_group">
          <label htmlFor="" className="form_label">
            Phone:
          </label>

          <div className="form_country_number_control sm_control send_control_number ">
            <PhoneInput
              international
              defaultCountry="BD"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </div>
        </div>

        <div className="form_group">
          <label htmlFor="" className="form_label">
            Subject:
          </label>
          <input
            type="text"
            className="form_control"
            placeholder="Subject..."
          />
        </div>
        <div className="form_group">
          <label htmlFor="" className="form_label">
            Message:
          </label>
          <textarea
            placeholder="Message..."
            rows="3"
            className="form_control"
          ></textarea>
        </div>
        <div className="form_group" style={{ justifyContent: "center" }}>
          <Button className="send_btn" sx={{ textTransform: "none" }}>
            Submit
          </Button>
        </div>
      </Box>
    </div>
  );
}
