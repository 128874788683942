import React, { useEffect, useState } from "react";
import "./AdvanceSearch.css";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Slider from "@mui/material/Slider";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import CountryDropdown from "country-dropdown-with-flags-for-react";

function AdvanceSearch({ open, handleClose }) {
  const [rangeOpen, setRangeOpen] = React.useState(false);
  const [pageScroll, setPageScroll] = React.useState(0);
  const handleRange = (event) => {
    setRangeOpen(!rangeOpen);
  };
  window.addEventListener("scroll", function (e) {
    //console.log(window.pageYOffset);
    setPageScroll(window.pageYOffset);
  });
  const style = {
    position: "absolute",
    top: `calc(23% - ${pageScroll}px)`,
    left: "0%",
    width: 300,
    bgcolor: "var(--auto-text-47)",
    border: "none",
    p: 4,
    color: "var(--auto-text-white)",
    borderRadius: "0 15px 15px 0",
  };

  // .............................Input forms values........................
  const [kilometersState, setKilometersState] = useState("2"); // default 2
  const [fuelState, setFuelState] = useState("2"); // default 2
  const [transmissionState, setTransmissionState] = useState("2"); // default 2
  const [powerState, setPowerState] = useState("2"); // default 2
  const [psState, setPsState] = useState("2"); // default 2
  const [driveState, setDriveState] = useState("2"); // default 2
  const [vehicleTypeState, setVehicleTypeState] = useState("2"); // default 2
  const [doorsState, setDoorsState] = useState("2"); // default 2
  const [exteriorColorState, setExteriorColorState] = useState("2"); // default 2
  const [interiorPaintState, setInteriorPaintState] = useState("2"); // default 2
  const [typesOfCarsState, setTypesOfCarsState] = useState("2"); // default 2
  const [furnishingState, setFurnishingState] = useState("2"); // default 2
  const [consuptionState, setConsumptionState] = useState("2"); // default 2
  // .............................Input forms values........................End
  return (
    <div id="AdvanceSearch">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            className="title_line"
            component="h2"
          >
            Advance Search
          </Typography>
          <Typography
            id="modal-modal-description"
            component="div"
            sx={{ mt: 5 }}
          >
            <form action="" method="post" className="form_search">
              <Box
                className="form_group"
                sx={{ mb: 6, cursor: "pointer" }}
                onClick={handleRange}
              >
                <select
                  className="select_control"
                  defaultValue="s"
                  style={{ pointerEvents: "none" }}
                >
                  <option value="s" disabled>
                    Price Range
                  </option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>
              {rangeOpen && (
                <Box className="form_group" sx={{ mb: 6 }}>
                  <RangeSlider width="100%"></RangeSlider>
                </Box>
              )}

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  onClick={(event) => setKilometersState(event.target.value)}
                  defaultValue="s"
                >
                  <option value="s" disabled>
                    Kilometers
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setFuelState(event.target.value)}
                >
                  <option value="s" disabled>
                    Fuel
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setTransmissionState(event.target.value)}
                >
                  <option value="s" disabled>
                    Transmission
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setPowerState(event.target.value)}
                >
                  <option value="s" disabled>
                    Power
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setPsState(event.target.value)}
                >
                  <option value="s" disabled>
                    PS
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setDriveState(event.target.value)}
                >
                  <option value="s" disabled>
                    Drive
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setVehicleTypeState(event.target.value)}
                >
                  <option value="s" disabled>
                    Vehicle Type
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setDoorsState(event.target.value)}
                >
                  <option value="s" disabled>
                    Doors
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setExteriorColorState(event.target.value)}
                >
                  <option value="s" disabled>
                    Exterior Color
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setInteriorPaintState(event.target.value)}
                >
                  <option value="s" disabled>
                    Interior Paint
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setTypesOfCarsState(event.target.value)}
                >
                  <option value="s" disabled>
                    Types of Cars
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setFurnishingState(event.target.value)}
                >
                  <option value="s" disabled>
                    Furnishing
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select
                  className="select_control"
                  defaultValue="s"
                  onClick={(event) => setConsumptionState(event.target.value)}
                >
                  <option value="s" disabled>
                    Consumption, Energy
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select className="select_control" defaultValue="s">
                  <option value="s" disabled>
                    Country
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select className="select_control" defaultValue="s">
                  <option value="s" disabled>
                    Postcode / Town
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select className="select_control" defaultValue="s">
                  <option value="s" disabled>
                    Radius
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>

              <Box className="form_group" sx={{ mb: 6 }}>
                <select className="select_control" defaultValue="s">
                  <option value="s" disabled>
                    Age of Ad
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
              </Box>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default AdvanceSearch;

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;

function RangeSlider() {
  const [value1, setValue1] = React.useState([20, 37]);
  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };
  return (
    <Box width="100%">
      <Slider
        getAriaLabel={() => "Minimum distance"}
        value={value1}
        onChange={handleChange1}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        disableSwap
      />

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          From:{" "}
          <span style={{ padding: "2px 5px" }} className="btn_cyan_style">
            {value1[0]}
          </span>
        </Box>
        <Box>
          To:{" "}
          <span style={{ padding: "2px 5px" }} className="btn_cyan_style">
            {value1[1]}
          </span>
        </Box>
      </Box>
    </Box>
  );
}

// AdvanceSearch drawer ,,,,,,,,,,,,,,,,,,,,,,,,,,,

export function AdvanceSearchDrawer({
  presentationClickHandlle,
  presentationKeyDown,
  drawerOnClose,
  openState,
}) {
  return (
    <div>
      <React.Fragment>
        <Drawer anchor={"left"} open={openState} onClose={drawerOnClose}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              position: "sticky",
              top: "0px",
              bgcolor: "#595A5C",
              zIndex: "1000",
              opacity: 1,
              color: "var(--auto-text-white)",
            }}
          >
            <Box sx={{ m: 2 }} className="title_line">
              Advance Search
            </Box>
          </Typography>
          <Box
            sx={{
              width: 250,
              bgcolor: "var(--auto-text-47)",
              color: "var(--auto-text-white)",
              p: 2,
            }}
          >
            <DrawerBox></DrawerBox>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const DrawerBox = () => {
  const [rangeOpen, setRangeOpen] = React.useState(false);
  const [pageScroll, setPageScroll] = React.useState(0);
  const handleRange = (event) => {
    setRangeOpen(!rangeOpen);
  };

  // ........................input forms value for db request....................

  // for country
  const [countryState, setCountryState] = useState("Bangladesh (বাংলাদেশ)"); // default bd
  //console.log(countryState)

  return (
    <Box>
      <Typography
        id="modal-modal-description"
        component="div"
        sx={{ mt: 5, position: "relative" }}
      >
        <form action="" method="post" className="form_search">
          <Box
            className="form_group"
            sx={{ mb: 6, cursor: "pointer" }}
            onClick={handleRange}
          >
            <select
              className="select_control"
              defaultValue="s"
              style={{ pointerEvents: "none" }}
            >
              <option value="s" disabled>
                Price Range
              </option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>
          {rangeOpen && (
            <Box className="form_group" sx={{ mb: 6 }}>
              <RangeSlider width="100%"></RangeSlider>
            </Box>
          )}

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Kilometers
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Fuel
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Transmission
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Power
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                PS
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Drive
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Vehicle Type
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Doors
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Exterior Color
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Interior Paint
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Types of Cars
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Furnishing
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Consumption, Energy
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <CountryDropdown
              style={{ padding: "0px" }}
              className="form_control"
              preferredCountries={["bd", "in"]}
              value={countryState}
              handleChange={(e) => setCountryState(e.target.value)}
            ></CountryDropdown>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Postcode / Town
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Radius
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>

          <Box className="form_group" sx={{ mb: 6 }}>
            <select className="select_control" defaultValue="s">
              <option value="s" disabled>
                Age of Ad
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
          </Box>
        </form>
      </Typography>
    </Box>
  );
};
