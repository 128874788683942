import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getRegisterToken } from '../../Redux/Action/Register';
import { VerifyEmailOtp } from "../../Adapters/Auth";
import { toast } from "react-toastify";
import  {useNavigate } from "react-router-dom";
import {useSelector} from  'react-redux';
function VerifyEmail() {
    const [registerToken,setRegisterToken]= useState("");
    const navigate = useNavigate();
   
    const[serchparams,setSerchParams]= useSearchParams();
    const dispatch = useDispatch();
    useEffect(() => {
      
      setRegisterToken(serchparams.get("token"));
      console.log("token",serchparams.get("token"));
       
         handleUserVerify();

      }, []);
let tokenvalue = serchparams.get("token");
      // useEffect(() => {
        
      //   handleUserVerify();
      //   }, [token]);
const handleUserVerify = ()=>{
  
  let toast_id = toast.loading('Please wait...')
  VerifyEmailOtp(tokenvalue,(sucess)=>{
    if(sucess)
    {
      
      navigate("/user_panel");
    }
  },(err)=>{

    toast.update(
      toast_id,
      {
        render : err,
        type : 'error',
        isLoading : false,
        closeOnClick : true,
        autoClose : 5000,
        pauseOnHover : true,
      }
    )
  })
}
  return (
    <>
    </>
  )
}

export default VerifyEmail



