import "./App.scss";
import Base from "./Layout/Base/Base";
import Homepage from "./Pages/Homepage/Homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// ........................Pages import.....................
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import ContactUs from "./Pages/ContactUs/ContactUs";
import RecoverPassword from "./Pages/RecoverPassword/RecoverPassword";
import CarDetails from "./Pages/CarDetails/CarDetails";
import BuyCarComponents from "./Pages/BuyCarComponents/BuyCarComponents";
import Dealer from "./Pages/Dealer/Dealer";
import MerchantDetails from "./Pages/MerchantDetails/MerchantDetails";
import RentGarage from "./Pages/RentGarage/RentGarage";
import RentGarage2 from "./Pages/RentGarage2/RentGarage2";
import SaleGarage from "./Pages/SaleGarage/SaleGarage";
import BikeDetails from "./Pages/BikeDetails/BikeDetails";
import BuyBikeComponents from "./Pages/BuyBikeComponents/BuyBikeComponents";
import Aboutpage from "./Pages/Aboutpage/Aboutpage";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import CookiesPage from "./Pages/Cookies/Cookies";
import Vacancy from "./Pages/Vacancy/Vacancy";
import SellingAndBuyingRule from "./Pages/SellingAndBuyngRule/SellingAndBuyngRule";
import LocalAdvertisingRule from "./Pages/LocalAdvertisingRule/LocalAdvertisingRule";
import Imprint from "./Pages/Imprint/Imprint";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import BuyBikes from "./Pages/BuyBikes/BuyBikes";
import BuyCar from "./Pages/BuyCar/BuyCar";
import SellCar1 from "./Pages/SellCar1/SellCar1";
import SellCar2 from "./Pages/SellCar2/SellCar2";
import UserPanel from "./Pages/UserPanel/UserPanel";
import CarAdvertisement from "./Pages/CarAdvertisement/CarAdvertisement";
import SellBike1 from "./Pages/SellBike1/SellBike1";
import CarAdvertisement2 from "./Pages/CarAdvertisement2/CarAdvertisement2";
import AdvertisementHistory from "./Pages/AdvertisementHistory/AdvertisementHistory";
import CarComponent from "./Pages/CarComponent/CarComponent";
import ComponentEnquiry from "./Pages/ComponentEnquiry/ComponentEnquiry";
import MerchantHistoryDetails from "./Pages/MerchantHistoryDetails/MerchantHistoryDetails";
import GarageDetails from "./Pages/GarageDetails/GarageDetails";
import GarageEnquiry from "./Pages/GarageEnquiry/GarageEnquiry";
import CarBuyHistory from "./Pages/BuyHistory/CarBuyHistory";
import AddCarComponent from "./Pages/AddCarComponent/AddCarComponent";
import SellBike2 from "./Pages/SellBike2/SellBike2";
import BikeComponent from "./Pages/BikeComponent/BikeComponent";
import AddBikeComponent from "./Pages/AddBikeComponent/AddBikeComponent";
import EditProfile from "./Pages/EditProfile/EditProfile";
import AddGarage from "./Pages/AddGarage/AddGarage";
import GarageChoosePackage from "./Pages/GarageChoosePackage/GarageChoosePackage";
import AddMerchant from "./Pages/AddMerchant/AddMerchant";
import CarBookingHistory from "./Pages/BookingHistory/CarBookingHistory";
import BikeBookingHistory from "./Pages/BookingHistory/BikeBookingHistory";
import ComponentBookingHistory from "./Pages/BookingHistory/ComponentBookingHistory";
import GarageBookingHistory from "./Pages/BookingHistory/GarageBookingHistory";
import BikeBuyHistory from "./Pages/BuyHistory/BikeBuyHistory";
import CarSellHistory from "./Pages/SellHistory/CarSellHistory";
import BikeSellHistory from "./Pages/SellHistory/BikeSellHistory";
import VerifyEmail from "./Pages/VerifyEmail/VerifyEmail";
import Locale from "./utils/Locale";
import CommingSoon from "./Pages/CommingSoon/CommingSoon";
import { VerifyEmailOtp } from "./Adapters/Auth";
import Forgetpassword from "./Pages/ForgetPassword/Forgetpassword";
// ........................Pages import..................... End

function App() {
  return (
    <Locale>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route path="/" element={<CommingSoon />} />
            <Route path="/home" element={<Homepage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/contact_us" element={<ContactUs />} />
            <Route path="/recover_password" element={<RecoverPassword />} />
            <Route path="/car_details" element={<CarDetails />} />
            <Route path="/buy_car_components" element={<BuyCarComponents />} />
            <Route path="/dealer" element={<Dealer />} />
            <Route path="/merchant_details" element={<MerchantDetails />} />
            <Route path="/rent_garage" element={<RentGarage />} />
            <Route path="/rent_garage2" element={<RentGarage2 />} />
            <Route path="/sale_garage" element={<SaleGarage />} />
            <Route path="/bike_details" element={<BikeDetails />} />
            <Route path="/buy_bike_components" element={<BuyBikeComponents />} />
            <Route path="/about" element={<Aboutpage />} />
            <Route
              path="/terms_and_conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/cookies" element={<CookiesPage />} />
            <Route path="/vacancy" element={<Vacancy />} />
            <Route
              path="/selling_and_buying_rule"
              element={<SellingAndBuyingRule />}
            />
            <Route
              path="/local_advertising_rule"
              element={<LocalAdvertisingRule />}
            />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/buy_bikes" element={<BuyBikes />} />
            <Route path="/buy_car" element={<BuyCar />} />
            <Route path="/dashboard" element={<Homepage />} />
            <Route path="/sell_car1" element={<SellCar1 />} />
            <Route path="/sell_car2" element={<SellCar2 />} />
            <Route path="/user_panel" element={<UserPanel />} />
            <Route path="/car_advertisement" element={<CarAdvertisement />} />
            <Route path="/sell_bike1" element={<SellBike1 />} />
            <Route path="/sell_bike2" element={<SellBike2 />} />
            <Route path="/car_advertisement2" element={<CarAdvertisement2 />} />
            <Route
              path="/advertisement_history"
              element={<AdvertisementHistory />}
            />
            <Route path="/car_component" element={<CarComponent />} />
            <Route path="/component_enquiry" element={<ComponentEnquiry />} />
            <Route path="/car_booking_history" element={<CarBookingHistory />} />
            <Route
              path="/bike_booking_history"
              element={<BikeBookingHistory />}
            />
            <Route
              path="/component_booking_history"
              element={<ComponentBookingHistory />}
            />
            <Route
              path="/garage_booking_history"
              element={<GarageBookingHistory />}
            />
            <Route
              path="/merchant_history_details"
              element={<MerchantHistoryDetails />}
            />
            <Route path="/garage_details" element={<GarageDetails />} />
            <Route path="/garage_enquiry" element={<GarageEnquiry />} />
            <Route path="/car_buy_history" element={<CarBuyHistory />} />
            <Route path="/bike_buy_history" element={<BikeBuyHistory />} />
            <Route path="/car_sell_history" element={<CarSellHistory />} />
            <Route path="/bike_sell_history" element={<BikeSellHistory />} />
            <Route path="/add_car_component" element={<AddCarComponent />} />
            <Route path="/bike_component" element={<BikeComponent />} />
            <Route path="/add_bike_component" element={<AddBikeComponent />} />
            <Route path="/edit_profile" element={<EditProfile />} />
            <Route path="/change_password" element={<Forgetpassword />} />
            <Route path="/add_garage" element={<AddGarage />} />
            <Route
              path="/garage_choose_package"
              element={<GarageChoosePackage />}
            />
            <Route path="/add_merchant" element={<AddMerchant />} />

          </Routes>
        </Base>

      </BrowserRouter>
    </Locale>
  );
}

export default App;
