import axios from "axios";
 export const BaseUrl = 'http://api.autoby24.ch';
//  export const BaseUrl = 'https://f202-2400-1a00-b050-9888-e1fe-8dcf-1ba5-2b70.ngrok-free.app';
// export const BaseUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}`;

export default axios.create({
  baseURL:`${process.env.REACT_APP_BACKEND_BASE_URL}`,
});

export const user_register_url = "/user/registration/"
export const login_user_url = "/user/login/"
export const send_verify_code_email_urls = "user/send_verify_code_email/"
export const verify_user_otp_email_url = "/user/activate-account/"
export const change_password_url = "user/change_password_otp_email/"




export const userRegisterHttpRequest = axios.create({
  baseURL: BaseUrl + user_register_url,
});

export const userLoginHttpRequest = axios.create({
  baseURL: BaseUrl + login_user_url,
});

export const userDetailHttpRequest = axios.create({
  baseURL: BaseUrl + "user/user_info/",
  headers: {
    Authorization:  `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const createProfileHttpRequest = axios.create({
  baseURL: BaseUrl + "/user/create-update-user-profile/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const updateProfileHttpRequest = axios.create({
  baseURL: BaseUrl + "user/update_profile/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const changePasswordHttpRequest = axios.create({
  baseURL: BaseUrl + "/user/change-password/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});

export const getAllPackagesHttpRequest = axios.create({
  baseURL: BaseUrl + "vehicle/get_all_packages_info/",
  headers: {
    Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
  },
});