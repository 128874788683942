import React from "react";

import LoginCardBlock from "../LoginCardBlock";
import firebase, { GoogleAuthprovider } from "../../../firebaseConf";
import { toast } from "react-toastify";

const LoginWithGoogle = ({ onVerify, children, is_agreed_terms_condition }) => {
  const handleGoogleLogin = () => {
    firebase
      .auth()
      .signInWithPopup(GoogleAuthprovider)
      .then(function (result) {
        let info = result.additionalUserInfo;
        let user = info.profile;

        let user_data = {
          // first_name: user.given_name,
          // last_name: user.family_name,
          email: user.email,
          social_platform: "Google",
          social_id: user.id,
          social_login: true,
          // image: user.picture,
          // is_email_verified: user.verified_email,
          username: user.name,
          // is_new_user: info.isNewUser,
        };
        console.log(user_data);
        onVerify(user_data);
      })
      .catch(function (error) {});
  };

  return (
    <>
      {/*  GoogleLogin implemented just commented  due to absence of clientId */}
      <div className="relative">
        <LoginCardBlock
          onClick={() => {
            if (!is_agreed_terms_condition){
              toast.error('Please agree terms & condition')
            }
            else{
              handleGoogleLogin();
            }
          }}
        >
          {children}
        </LoginCardBlock>
      </div>
    </>
  );
};

LoginWithGoogle.defaultProps = {
  onVerify: () => {},
};

export default LoginWithGoogle;
