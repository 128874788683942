import React, { useState } from 'react';
import "../../static/css/forms_style/forms_style.css";
import './RecoverPassword.scss';
import { Box, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ChangeEmailOtpPassword, VerifyEmailOtp, requestUserEmailOtp } from '../../Adapters/Auth';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
let ToastParams = {
    isLoading : false,
    closeOnClick : true,
    autoClose : 5000,
    pauseOnHover : true,
}
function RecoverPassword(props) {
    const navigate = useNavigate();
    const intl = useIntl();
    // const classes = useStyles();

    const [RecoverData, setRecoverData] = useState({activeField : 'EMAIL'})

    const handleSubmit = () => {
        let toast_id = toast.loading('Please wait, Authenticating...')
        if (RecoverData.activeField == 'EMAIL'){

            requestUserEmailOtp(
                {email : RecoverData.email},
                (result) =>{
                    setRecoverData({
                        ...RecoverData,
                        activeField : 'OTP'
                    })
                    toast.update(
                        toast_id,
                        {
                            render : result?.message,
                            type : 'success',
                            ...ToastParams
                        }
                    )
                },
                (message) =>{
                    toast.update(
                        toast_id,
                        {
                            render : message,
                            type : 'error',
                            ...ToastParams
                        }
                    )
                }
            )
        }
        else if (RecoverData.activeField == 'OTP'){
            VerifyEmailOtp(
                {email : RecoverData.email, code : RecoverData.otp},
                (result) =>{
                    setRecoverData({
                        ...RecoverData,
                        activeField : 'PASSWORD'
                    })
                    toast.update(
                        toast_id,
                        {
                            render : result?.message,
                            type : 'success',
                            ...ToastParams
                        }
                    )
                },
                (message) =>{
                    toast.update(
                        toast_id,
                        {
                            render : message,
                            type : 'error',
                            ...ToastParams
                        }
                    )
                }
            )
        }
        else if (RecoverData.activeField == 'PASSWORD'){
            ChangeEmailOtpPassword(
                {email : RecoverData.email, password : RecoverData.password},
                (result) =>{
                    navigate('/login')
                    toast.update(
                        toast_id,
                        {
                            render : result?.message,
                            type : 'success',
                            ...ToastParams
                        }
                    )
                },
                (message) =>{
                    toast.update(
                        toast_id,
                        {
                            render : message,
                            type : 'error',
                            ...ToastParams
                        }
                    )
                }
            )
        }
    }
    return (
        <div id='RecoverPassword'>
            <h2 className="form_name"><FormattedMessage id="recover-password"/></h2>
            <div className="content_wrapper form_wrapper">
                <h2 style={{ textAlign: "left" }}>
                    <Box
                        component="strong"
                        className="title_line"
                        sx={{ display: "inline-block" }}
                    >
                    <FormattedMessage id="recover-password"/>
                    </Box>
                </h2>

                <div action="" method="post" className='form_auth'>
                    <Box sx={{ mb: 2 }}>
                        <div className="form_group">
                            <label htmlFor="" className="form_label capitalize">
                                {RecoverData.activeField}: <span className="star_symbol">*</span>
                            </label>
                            {
                                RecoverData.activeField == 'EMAIL' &&
                                <input 
                                    autoComplete='off'
                                    type="email"
                                    className="form_control"
                                    placeholder={intl.formatMessage({ id: "enter-email" })}
                                    value={RecoverData.email}
                                    onChange={(e)=>{
                                        setRecoverData({
                                            ...RecoverData,
                                            email : e.target.value
                                        })
                                    }}
                                />
                            }
                            {
                                RecoverData.activeField == 'OTP' &&
                                <input 
                                    autoComplete='off'
                                    type="text"
                                    className="form_control"
                                    placeholder="Verification OTP..."
                                    name='otp'
                                    value={RecoverData.otp}
                                    onChange={(e)=>{
                                        setRecoverData({
                                            ...RecoverData,
                                            otp : e.target.value
                                        })
                                    }}
                                />
                            }
                            {
                                RecoverData.activeField == 'PASSWORD' &&
                                <div className='form-group'>
                                    <input 
                                        autoComplete='off'
                                        type="password"
                                        className="form_control mb-2"
                                        placeholder={intl.formatMessage({ id: "enter-password" })}
                                        value={RecoverData.password}
                                        onChange={(e)=>{
                                            setRecoverData({
                                                ...RecoverData,
                                                password : e.target.value
                                            })
                                        }}
                                    />
                                    <input 
                                        autoComplete='off'
                                        type="password"
                                        className="form_control"
                                        placeholder="Confirm Password..."
                                        value={RecoverData.confirm_password}
                                        onChange={(e)=>{
                                            setRecoverData({
                                                ...RecoverData,
                                                confirm_password : e.target.value
                                            })
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Button 
                            className="submit_btn" 
                            type="submit"
                            disabled={RecoverData.activeField == 'EMAIL' ? 
                                    !RecoverData.email
                                :
                                RecoverData.activeField == 'OTP' ?
                                    !RecoverData.otp :
                                RecoverData.activeField == 'PASSWORD' ?
                                    (!RecoverData.password || !RecoverData.confirm_password)
                                    :
                                    false
                            }
                            onClick={handleSubmit}
                        >
                           {RecoverData.activeField == 'EMAIL' ? 'Recover' : RecoverData.activeField == 'OTP' ? 'Verify' : 'Confirm Change' } 
                        </Button>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ px: 2, mb: 2 }}>
                        <Link className="title_line line_decoration" to="/login">
                        <FormattedMessage id="sign-in"/> 
                        </Link>
                        <Link className="title_line line_decoration" to="/register">
                        <FormattedMessage id="sign-up"/>
                        </Link>
                    </Box>
                </div>

            </div>
        </div>
    );
}

export default RecoverPassword;