const InitState = {
    token:""
  }
  
  const getRegisterToken = (state = InitState, action) => {
 
    switch (action.type) {
      
      case 'GET_REGISTER_TOKEN':
        return {
          ...state,
          token: action.payload
        }
  
      default:
        return state
    }
  }
  
  export default getRegisterToken;
  