import React, { useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "./AddCarComponent.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import MenuIcon from "@mui/icons-material/Menu";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));
function AddCarComponent(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div
      id="AddCarComponent"
      style={{
        marginLeft: matches ? "260px" : "0px",
      }}
    >
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
          Add Car Component
        </h3>
      </Box>
      <Box
        display="flex"
        sx={{ pb: 8, background: "var(--auto-text-f1)" }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: matches ? 6 : 1, maxWidth: "100%" }}>
          <form action="" className="form_auth">
            <div className="content_wrapper">
              <Box sx={{ p: 2 }}>
                <FormBox1></FormBox1>
                <FormBox2></FormBox2>
                <FormBox3></FormBox3>
                <FormBox4></FormBox4>
                <FormSubmit></FormSubmit>
              </Box>
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default AddCarComponent;

const FormBox1 = () => {
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Car Category:
                </label>
                <Box position="relative">
                  <select
                    name=""
                    id=""
                    className="form_control"
                    defaultValue="s"
                  >
                    <option value="s" disabled>
                      Select Category
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Car Subcategory:
                </label>
                <Box position="relative">
                  <select
                    name=""
                    id=""
                    className="form_control"
                    defaultValue="s"
                  >
                    <option value="s" disabled>
                      Select Subcategory
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Name
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Name..."
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Price:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Price..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox2 = () => {
  const handleUploader1 = (event) => {
    // make a response to send it in database
    //console.log(event.target.value);
  };

  const handleUploader2 = (event) => {
    // make a response to send it in database
    //console.log(event.target.value);
  };

  const handleUploader3 = (event) => {
    // make a response to send it in database
    //console.log(event.target.value);
  };

  return (
    <Box>
      <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12}>
          <Item sx={{ boxShadow: 0 }} className="upload_wrapper ">
            <label htmlFor="" className="form_label">
              Image:
            </label>
            <Box className="Upload_pictures_wrapper">
              <Box sx={{ mb: 2, mt: 3, mx: 2 }}>
                <div className="form_group">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    className="form_control"
                    placeholder="Year of Registration of Vehicle..."
                    id="Vehicle_rc_input"
                    onChange={handleUploader1}
                  />
                  <PictureUploader></PictureUploader>
                </div>
              </Box>
              <Box sx={{ mb: 2, mt: 3, mx: 2 }}>
                <div className="form_group">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    className="form_control"
                    placeholder="Year of Registration of Vehicle..."
                    id="Vehicle_rc_input"
                    onChange={handleUploader2}
                  />
                  <PictureUploader></PictureUploader>
                </div>
              </Box>
              <Box sx={{ mb: 2, mt: 3, mx: 2 }}>
                <div className="form_group">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    className="form_control"
                    placeholder="Year of Registration of Vehicle..."
                    id="Vehicle_rc_input"
                    onChange={handleUploader3}
                  />
                  <PictureUploader></PictureUploader>
                </div>
              </Box>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox3 = () => {
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Description:
                </label>
                <Box position="relative">
                  <textarea
                    placeholder="Description"
                    rows="7"
                    className="form_control"
                  ></textarea>
                </Box>
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox4 = () => {
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Other Information</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Nertww:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Nertww..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Newtest:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="newtest..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
const FormSubmit = () => {
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button type="submit" sx={{ px: 6 }} className="btn_cyan_style">
        SUBMIT
      </Button>
    </Box>
  );
};
