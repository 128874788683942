import React from "react";
import "./SellBike1.scss";
import { Box, Button, useMediaQuery } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "../../static/css/style_helper/style_helper.scss";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function SellBike1(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const package_object = [
    {
      id: 1,
      title: "ATB15D",
      cost: "30 CHF",
      period: "1-15 DAY",
      images: "UP TO 7",
      per_car_cost: " UP TO 15 & 50 CHF",
      images_count: 1,
    },
    {
      id: 2,
      title: "ATB15D",
      cost: "30 CHF",
      period: "1-15 DAY",
      images: "UP TO 7",
      per_car_cost: " UP TO 15 & 50 CHF",
      images_count: 1,
    },
  ];
  return (
    <div id="SellBike1">
      <Box sx={{ py: 6 }}>
        <div className="SellBike_header">
          <h3 className="cyan_style" style={{ width: matches ? "50%" : "90%" }}>
            Choose A Package
          </h3>
        </div>
        <Box id="section1" component="section" sx={{ mt: 6 }}>
          <Grid
            container
            rowSpacing={{ xs: 2, sm: 4, md: 6 }}
            columnSpacing={{ xs: 2, sm: 4, md: 6 }}
          >
            {package_object.map((v) => (
              <Grid item xs={12} sm={6} key={v.id}>
                <Item sx={{ boxShadow: 3, borderRadius: 4 }}>
                  <Box>
                    <h2 className="title_line">{v.title}</h2>
                  </Box>
                  <Box>
                    <table className="package_table table_form">
                      <tbody>
                        <tr>
                          <td>EACH VECHILE COSTS</td>
                          <td>
                            <div className="cyan_value_box">{v.cost}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>PERIOD</td>
                          <td>
                            <div className="cyan_value_box">{v.period}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>IMAGES</td>
                          <td>
                            <div className="cyan_value_box">{v.images}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>MORE PICTURES AND PER CAR COST</td>
                          <td>
                            <div className="cyan_value_box">
                              {v.per_car_cost}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>IMAGES COUNT</td>
                          <td>
                            <div className="cyan_input_box">
                              <div className="form_group">
                                <select
                                  defaultValue="s"
                                  className="select_control"
                                >
                                  <option value={v.images_count}>
                                    {v.images_count}
                                  </option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                </select>
                                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>

                  <Box sx={{ my: 3 }}>
                    <Button sx={{ py: 0, px: 3 }} className="btn_cyan_style">
                      TAKE PACKAGE
                    </Button>
                  </Box>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default SellBike1;
