import React, { useRef } from "react";
import "./SellBike2.scss";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, useMediaQuery } from "@mui/material";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));

function SellBike2(props) {
  return (
    <div id="SellBike2">
      <Box sx={{ pt: 6 }}>
        <h3 className="section_title_cyan max_size_cyan">Sell Motorcycle</h3>

        <Box id="section1" component="section" sx={{ mt: 6 }}>
          <form action="" className="form_auth">
            <div className="content_wrapper">
              <FormBox1></FormBox1>
              <FormBox2></FormBox2>
              <FormBox3></FormBox3>
              <FormBox4></FormBox4>
              <FormBox5></FormBox5>
              <FormBox6></FormBox6>
              <FormSubmit></FormSubmit>
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default SellBike2;

const FormBox1 = () => {
  const matches = useMediaQuery("(min-width:640px)");
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Personal Details</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  First Name:{" "}
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="First Name..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 2, mt: matches ? 3 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Last Name:{" "}
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Last Name..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox2 = () => {
  const handleUploader = (event) => {
    // make a response to send it in database
    //console.log(event.target.value);
  };
  const matches = useMediaQuery("(min-width:640px)");
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Vehicle Data</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid
          item
          xs={12}
          md={6}
          style={matches ? null : { display: "flex", justifyContent: "center" }}
        >
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 2, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Vehicle_rc:
                </label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  className="form_control"
                  placeholder="Year of Registration of Vehicle..."
                  id="Vehicle_rc_input"
                  onChange={handleUploader}
                />
                <PictureUploader></PictureUploader>
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 2, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Year of Registration of Vehicle:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Year of Registration of Vehicle..."
                />
              </div>
            </Box>

            <Box sx={{ mb: 2, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Month of Registration of Vehicle:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Month of Registration of Vehicle..."
                />
              </div>
            </Box>

            <Box sx={{ mb: 2, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Vehicle No:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Vehicle No..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox3 = () => {
  const handleUploader1 = (event) => {
    // make a response to send it in database
    //console.log(event.target.value);
  };

  const handleUploader2 = (event) => {
    // make a response to send it in database
    //console.log(event.target.value);
  };

  const handleUploader3 = (event) => {
    // make a response to send it in database
    //console.log(event.target.value);
  };
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Upload Pictures</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Item
            sx={{
              boxShadow: 0,
              border: "2px dashed var(--auto-text-white)",
              borderRadius: "var(--radius-lg)",
            }}
            className="Upload_pictures_wrapper"
          >
            <Box sx={{ mb: 2, mt: 3, mx: 2 }}>
              <div className="form_group">
                <input
                  style={{ display: "none" }}
                  type="file"
                  className="form_control"
                  placeholder="Year of Registration of Vehicle..."
                  id="Vehicle_rc_input"
                  onChange={handleUploader1}
                />
                <PictureUploader></PictureUploader>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 3, mx: 2 }}>
              <div className="form_group">
                <input
                  style={{ display: "none" }}
                  type="file"
                  className="form_control"
                  placeholder="Year of Registration of Vehicle..."
                  id="Vehicle_rc_input"
                  onChange={handleUploader2}
                />
                <PictureUploader></PictureUploader>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 3, mx: 2 }}>
              <div className="form_group">
                <input
                  style={{ display: "none" }}
                  type="file"
                  className="form_control"
                  placeholder="Year of Registration of Vehicle..."
                  id="Vehicle_rc_input"
                  onChange={handleUploader3}
                />
                <PictureUploader></PictureUploader>
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox4 = () => {
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Listing Options</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Brand</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Model</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Brand</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Model</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Brand</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Model</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Brand</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Model</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Brand</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Model</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Brand</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item sx={{ boxShadow: 0 }} className="Upload_pictures_wrapper">
            <Box sx={{ mb: 0, mt: 0, width: "100%" }}>
              <Box className="form_search">
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s">Model</option>
                    <option value="1" disabled>
                      2
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Box>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
const FormBox5 = () => {
  const matches = useMediaQuery("(min-width:640px)");
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Other Information</h2>
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0, mt: 3 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Newtestnewret:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="newtestnewret..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 2, mt: matches ? 3 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Newtestcar
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="newtestcar..."
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox6 = () => {
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Sell Online</h2>
      </Box>
    </Box>
  );
};

const FormSubmit = () => {
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button type="submit" sx={{ px: 6 }} className="btn_cyan_style">
        SUBMIT
      </Button>
    </Box>
  );
};
