import React, { useState } from "react";
import "../../static/css/forms_style/forms_style.css";
import "./Register.scss";
import { Button, Box, Tooltip } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, useNavigate } from "react-router-dom";
import { userRegisterHttpRequest } from "../../static/http-content";
import { makeStyles } from "@mui/styles";
import { RegisterNewUser } from "../../Adapters/Auth";
import { toast } from "react-toastify";
import LoginWithGoogle from "../../Components/SocialsLogin/LoginWithGoogle";
import LoginWithFacebook from "../../Components/SocialsLogin/LoginWithFacebook";
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#000",
  },
}));
function Register(props) {
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate()
  const [registrationData, setRegistrationData] = useState({
    user_type: "private",
    email: null,
    password: null,
    // agree: false,
  });
console.log("usertype",registrationData.user_type)
  const handleRegister = (data) => {
 
    let toast_id = toast.loading('Please wait...')
    
    
      RegisterNewUser({email:registrationData.email,password:registrationData.password,user_type:registrationData.user_type}, (sucess) => {
        if(sucess){
     
        toast.update(
          toast_id,
          {
            render : sucess?.message,
            type : 'success',
            isLoading : false,
            closeOnClick : true,
            autoClose : 5000,
            pauseOnHover : true,
          },
         
        )
        navigate('/login')
        }
        
       
      }, (message) => { 
        if(message)
        {
          toast.update(
            toast_id,
            {
              render : message,
              type : 'error',
              isLoading : false,
              closeOnClick : true,
              autoClose : 5000,
              pauseOnHover : true,
            }
          )
        }
        
      })
  
    
    
  }

  const submitRegistrationData = () => {
    handleRegister(registrationData)

  };

  return (
    <div id="Register">
      <h2 className="form_name"></h2>
      <div className="content_wrapper form_wrapper">
        <h3 className="form_inner_name"><FormattedMessage id="register-to- your-Account"/></h3>
        <Box sx={{ mb: 2 }}>
          <div className="form_social_auth title_line">
            <LoginWithGoogle
              is_agreed_terms_condition={registrationData.agree}
              onVerify={(data) => {
                handleRegister({ ...registrationData, ...data })
              }}
            >
              <Button sx={{ p: 1 }} className="form_google_auth">
                {" "}
                <GoogleIcon></GoogleIcon> <FormattedMessage id="sign-in-google"/>
              </Button>
            </LoginWithGoogle>
            <LoginWithFacebook 
            is_agreed_terms_condition={registrationData.agree} onVerify={(data) => {
              handleRegister({ ...registrationData, ...data })
            }}>
              <Button sx={{ p: 1 }} className="form_facebook_auth">
                {" "}
                <FacebookIcon></FacebookIcon>  <FormattedMessage id="sign-in-Facebook"/>
              </Button>
            </LoginWithFacebook>
          </div>
        </Box>

        <Box align="center" display="flex" justifyContent="center">
          <Tabs
             value={registrationData.user_type}
            onChange={(event, newValue) =>
              setRegistrationData({ ...registrationData, user_type: newValue })
            }
            centered
            className="overlap_tabs"
          >
            <Tab
              sx={{ flex: 1, textTransform: "none", color: "black" }}
              disableRipple
              label={intl.formatMessage({ id: "private" })}
              value="private"
            />
            <Tab
              sx={{ flex: 1, textTransform: "none", color: "black" }}
              disableRipple
              label={intl.formatMessage({ id: "dealer" })}
              value="dealer"
            />
          </Tabs>
        </Box>

        <form action="" className="form_auth" method="post">
          <Box sx={{ mb: 2, mt: 3 }}>
            <div className="form_group">
              <label htmlFor="" className="form_label">
              <FormattedMessage id="email"/>: <span className="star_symbol">*</span>
              </label>
              <input
                autoComplete="off"
                type="email"
                name={
                  registrationData === "2" ? "private_email" : "dealer_email"
                }
                className="form_control"
                placeholder={intl.formatMessage({ id: "enter-email" })}
                value={registrationData.email}
                onChange={(event) =>
                  setRegistrationData({
                    ...registrationData,
                    email: event.target.value,
                  })
                }
              />
            </div>
          </Box>
          <Box sx={{ mb: 3 }}>
            <div className="form_group">
              <label htmlFor="" className="form_label">
              <FormattedMessage id="password"/>: <span className="star_symbol">*</span>
              </label>
              <input
                autoComplete="off"
                type="password"
                className="form_control"
                placeholder={intl.formatMessage({ id: "enter-password" })}
                value={registrationData.password}
                onChange={(event) =>
                  setRegistrationData({
                    ...registrationData,
                    password: event.target.value,
                  })
                }
              />
            </div>
          </Box>
          <Box sx={{ mb: 3, textAlign: "center" }} className="term_confirm_box">
            <input
              autoComplete="off"
              type="checkbox"
              id="read_agree"
              defaultChecked={registrationData.agree}
              onChange={() =>
                setRegistrationData({
                  ...registrationData,
                  agree: !registrationData.agree,
                })
              }
            />
            <label htmlFor="read_agree">  <FormattedMessage id="I-have-read-the"/> </label>
            <Link
              className="title_line line_decoration"
              to="/terms_and_conditions"
            >
            <FormattedMessage id="terms-and-conditions"/>
            </Link>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Tooltip
              placement="bottom-end"
              classes={{
                tooltip: classes.customTooltip,
                // arrow: classes.customArrow,
              }}
              title={
                registrationData.agree
                  ? null
                  : "Please agree with terms and conditions"
              }
            >
              <div>
                <Button
                  className="submit_btn"
                  onClick={submitRegistrationData}
                  disabled={
                    (
                      !registrationData.agree ||
                      !registrationData.password ||
                      !registrationData.email
                    ) ? true : false
                  }
                >
                <FormattedMessage id="register"/>
                </Button>
              </div>
            </Tooltip>
          </Box>
          <Box sx={{ textAlign: "center", mb: 2 }}>
          <FormattedMessage id="have-an-account ?"/> 
            <Link to="/login" className="title_line line_decoration">
            <FormattedMessage id="sign-in"/> 
            </Link>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default Register;
