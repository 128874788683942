const InitState = {
    token:""
  }
  
  const getLoginuserToken = (state = InitState, action) => {
 
    switch (action.type) {
      
      case 'GET_LOGIN_TOKEN':
        return {
          ...state,
          token: action.payload
        }
  
      default:
        return state
    }
  }
  
  export default getLoginuserToken;
  