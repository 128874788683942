import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import SliderThumb from "../../Components/SliderThumb/SliderThumb";
import "./BikeDetails.scss";
import { Button, useMediaQuery } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ProductSlider from "../../Components/ProductSlider/ProductSlider";

import PhoneInput from "react-phone-number-input";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function BikeDetails(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const sliderObject = {
    big_image: require("../../static/img/harley-davidson-YsMg1pJqqKk-unsplash.jpg"),
    thumb_img_list: [
      {
        id: 1,
        big: require("../../static/img/harley-davidson-YsMg1pJqqKk-unsplash.jpg"),
        small: require("../../static/img/harley-davidson-YsMg1pJqqKk-unsplash.jpg"),
      },
      {
        id: 2,
        big: require("../../static/img/harley-davidson-eeTJKC_wz34-unsplash.jpg"),
        small: require("../../static/img/harley-davidson-eeTJKC_wz34-unsplash.jpg"),
      },
      {
        id: 3,
        big: require("../../static/img/pairach-boriboonmee-PDMR0_IUjro-unsplash.jpg"),
        small: require("../../static/img/pairach-boriboonmee-PDMR0_IUjro-unsplash.jpg"),
      },
      {
        id: 4,
        big: require("../../static/img/pairach-boriboonmee-PDMR0_IUjro-unsplash.jpg"),
        small: require("../../static/img/pairach-boriboonmee-PDMR0_IUjro-unsplash.jpg"),
      },

      {
        id: 5,
        big: require("../../static/img/harley-davidson-YsMg1pJqqKk-unsplash.jpg"),
        small: require("../../static/img/harley-davidson-YsMg1pJqqKk-unsplash.jpg"),
      },
      {
        id: 6,
        big: require("../../static/img/harley-davidson-eeTJKC_wz34-unsplash.jpg"),
        small: require("../../static/img/harley-davidson-eeTJKC_wz34-unsplash.jpg"),
      },
      {
        id: 7,
        big: require("../../static/img/pairach-boriboonmee-PDMR0_IUjro-unsplash.jpg"),
        small: require("../../static/img/pairach-boriboonmee-PDMR0_IUjro-unsplash.jpg"),
      },
    ],
  };
  return (
    <div id="BikeDetails">
      <Box component="section" id="section1" sx={{ width: "100%" }}>
        <div className="BikeDetails_header">
          <h3 className="cyan_style" style={{ width: matches ? "50%" : "70%" }}>
            Bike Details
          </h3>
        </div>
        <Grid
          alignItems="stretch"
          container
          rowSpacing={1}
          columnSpacing={{ xs: 0, sm: 0, md: 0 }}
        >
          <Grid item xs={12} md={7} sx={{ height: "100%" }}>
            <Item sx={{ boxShadow: 0, height: "100%", pb: 0, px: 0 }}>
              <SliderThumb
                SliderName="bike_details"
                sliderObject={sliderObject}
              ></SliderThumb>
            </Item>
          </Grid>
          <Grid item xs={12} md={5}>
            <Item
              sx={{
                boxShadow: 0,
                p: matches ? null : 0,
                pt: matches ? null : 2,
              }}
            >
              <ProductDetails></ProductDetails>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Section2></Section2>

      <Section3></Section3>
    </div>
  );
}

export default BikeDetails;

function ProductDetails() {
  const product_address_object = [
    {
      id: 1,
      name: "Address:",
      info: "80989, Densburen, AG, CH, 5026",
    },
    {
      id: 2,
      name: "Email:",
      info: "banwari.hawkscode@gmail.com",
    },
    {
      id: 3,
      name: "Phone:",
      info: (
        <>
          8058191887 <Button className="calling_btn ">Call</Button>
        </>
      ),
    },
  ];

  const [phoneNumber, setPhoneNumber] = useState();

  return (
    <div id="productDetails">
      <Box sx={{ px: 2 }}>
        <Box className="product_title title_line">
          <h2 data-after-text="2015" style={{ marginTop: "0px" }}>
            HARLEY 975
          </h2>
          <h3>17000.00 CHF</h3>
        </Box>

        <Box className="product_address">
          <table className="product_address_table">
            <tbody>
              {product_address_object.map((v) => {
                return (
                  <tr key={v.id}>
                    <td>{v.name}</td>
                    <td>{v.info}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Button className="buy_btn title_line">Buy Now</Button>
        </Box>

        <Box className="product_forms">
          <h3>Send email to car owner</h3>
          <div className="form_group">
            <label htmlFor="" className="form_label">
              Name:
            </label>
            <input type="text" className="form_control" placeholder="Name..." />
          </div>

          <div className="form_group">
            <label htmlFor="" className="form_label">
              Email:
            </label>
            <input
              type="email"
              className="form_control"
              placeholder="Email..."
            />
          </div>

          <div className="form_group">
            <label htmlFor="" className="form_label">
              Phone:
            </label>

            <div className="form_country_number_control send_control_number">
              <PhoneInput
                international
                defaultCountry="BD"
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </div>
          </div>

          <div className="form_group">
            <label htmlFor="" className="form_label">
              Subject:
            </label>
            <input
              type="text"
              className="form_control"
              placeholder="Subject..."
            />
          </div>
          <div className="form_group">
            <label htmlFor="" className="form_label">
              Message:
            </label>
            <textarea
              placeholder="Message..."
              rows="3"
              className="form_control"
            ></textarea>
          </div>
          <div className="form_group" style={{ justifyContent: "center" }}>
            <Button className="send_btn">Send</Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}

function Section2() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const mustang_gt_object = {
    name: "MUSTANG GT",
    list: [
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
      "Jun 02, 2021",
    ],
  };
  return (
    <Box component="section" id="section2">
      <Box className="section_container">
        <Box align="center" display="flex" justifyContent="center">
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            className="overlap_tabs"
          >
            <Tab
              disableRipple
              sx={{ color: "var(--auto-text-black)" }}
              label="Details"
            />
            <Tab
              disableRipple
              sx={{ color: "var(--auto-text-black)" }}
              label="Fetures"
            />
          </Tabs>
        </Box>
        <Box className="tabBox" sx={{ my: 5 }}>
          {value === 0 ? (
            <Box>
              <h3 className="title_line">Description</h3>
              <Box component="p" sx={{ my: 3 }}>
                When you buy a vehicle, you receive 12 months of TCS breakdown
                service (Swizerland) free of charge. kjkjkjk 12 mois de
                I'assistance routiere TCS gratuit, pour I'achat de votre voiture
                chez nous.
              </Box>

              <h3 className="title_line">Other Information</h3>
              <Box>
                <span>newtestnewret:</span>
                <span style={{ paddingLeft: "25px" }}>Testing</span>
              </Box>
            </Box>
          ) : (
            <>
              <h2 className="title_line">{mustang_gt_object.name}</h2>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 2, sm: 4, md: 7 }}
              >
                {mustang_gt_object.list.map((v, i) => (
                  <Grid item xs={12} md={6} key={i}>
                    <Item sx={{ borderRadius: "var(--radius-pill)", px: 3 }}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        color="black"
                      >
                        <span>Added:</span>
                        <span>{v}</span>
                      </Box>
                    </Item>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

function Section3() {
  const sliderObject = {
    slider_name: "Related Products",
    slider_items: [
      {
        id: 1,
        img: require("../../static/img/harley-davidson-YsMg1pJqqKk-unsplash.jpg"),
        title: "HARLEY 975",
        link: "#",
        price: "17000.00 CHF",
      },
      {
        id: 2,
        img: require("../../static/img/pairach-boriboonmee-PDMR0_IUjro-unsplash.jpg"),
        title: "KAWASAKI",
        link: "",
        price: "17000.00 CHF",
      },
      {
        id: 3,
        img: require("../../static/img/harley-davidson-eeTJKC_wz34-unsplash.jpg"),
        title: "HARLEY",
        link: "",
        price: "17000.00 CHF",
      },
      {
        id: 4,
        img: require("../../static/img/harley-davidson-eeTJKC_wz34-unsplash.jpg"),
        title: "VOLKSWAGEN",
        link: "",
        price: "17000.00 CHF",
      },
    ],
  };

  return (
    <Box component="section" id="section3">
      <Box className="section_container">
        <ProductSlider
          sliderName="related_product"
          cardExtraPadding={"15px"}
          cardExtraContent={false}
          sliderObject={sliderObject}
        ></ProductSlider>
      </Box>
    </Box>
  );
}
