import LanguageReducer from './languageReducer'
import LoginReducer from './LoginReducer'
import RegisterReducer from './RegisterReducer'
 import { combineReducers } from 'redux'

const rootReducer = combineReducers({
    LanguageReducer: LanguageReducer,
    LoginReducer:LoginReducer,
    RegisterReducer:RegisterReducer

})

export default rootReducer
