import React from "react";
import FacebookLogin from "react-facebook-login";

import LoginCardBlock from "../LoginCardBlock";
import { toast } from "react-toastify";

const LoginWithFacebook = ({ onVerify, login, children, is_agreed_terms_condition }) => {
  const facebookHandler = (response) => {
    if (response.status != "unknown"){
      console.log(response)
      onVerify({...response, social_login: true,});
    }
  };

  return (
    <>
      {/*  FacebookLogin implemented just commented  due to absence of appId */}
      <div className="relative">
        <LoginCardBlock >
          <span className="absolute opacity-0 inset-0 p-0" onClick={()=>{
            if (!is_agreed_terms_condition){
              toast.error('Please agree terms & condition')
            }
          }}>
            <FacebookLogin
              // appId="858830515002290"
              appId="509216401338271"
              autoLoad={false}
              fields="name,email,picture,first_name,last_name"
              // onClick={onClickHandler}
              callback={facebookHandler}
              textButton={children}
              isDisabled={!is_agreed_terms_condition}
              size={"medium"}
              cssClass="absolute opacity-0 inset-0 p-0 px-0 bg-light bg-white outline-none border-none"
            />
          </span>
        </LoginCardBlock>
      </div>
    </>
  );
};

LoginWithFacebook.defaultProps = {
  onVerify: () => { },
};

export default LoginWithFacebook;
