import React, { useState } from "react";
import "./ProductMaterialCard.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Box,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "../../static/css/style_helper/style_helper.scss";

import FavoriteIcon from "@mui/icons-material/Favorite";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export function ProductMaterialCard1({ cardObject }) {
  const [favoriteIcon, setFavoriteIcon] = useState(false);
  const matches = useMediaQuery("(min-width:640px)");

  return (
    <div id="ProductMaterialCard" className="ProductMaterialCard1">
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "row" : "column"}
      >
        <div
          className="product_wrapper"
          style={{ flexShrink: 50, width: "100%" }}
        >
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 3 }}>
            {cardObject.map((v) => (
              <Grid item xs={12} md={6} lg={4} key={v.id}>
                <Item sx={{ boxShadow: 0 }}>
                  <Card
                    sx={{
                      boxShadow: "2px 2px 5px 1px #ddd",
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                  >
                    <CardActionArea component="div">
                      <Box height="220" position="relative" component="div">
                        <CardMedia
                          component="img"
                          height="100%"
                          image={v.img}
                          className="card_media_img"
                          alt="img"
                        />
                        <Button className="price_btn"> {v.price} </Button>
                      </Box>
                    </CardActionArea>
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                        m: 0,
                        my: 2,
                        p: 0,
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        color="black"
                        sx={{
                          fontWeight: "bold",
                          mb: 0,
                          flex: 1,
                          textAlign: "center",
                        }}
                        align="center"
                      >
                        {v.title}
                      </Typography>
                      <Box
                        sx={{ mr: 3, cursor: "pointer" }}
                        onClick={(event) => setFavoriteIcon(v.id)}
                      >
                        {favoriteIcon === v.id ? (
                          <FavoriteIcon
                            sx={{ fill: "red", fontSize: "2rem" }}
                          ></FavoriteIcon>
                        ) : (
                          <FavoriteBorderIcon
                            sx={{
                              fontSize: "2rem",
                            }}
                          ></FavoriteBorderIcon>
                        )}
                      </Box>
                    </CardContent>
                    <CardActions sx={{ px: 0, pb: 0 }}>
                      <Button
                        size="large"
                        sx={{ width: "100%", borderRadius: 3, py: 2 }}
                        className="btn_view_more"
                        component="a"
                        href={v.link}
                      >
                        <span className="span_text">
                          View more{" "}
                          <ArrowForwardIcon
                            sx={{ marginLeft: 1 }}
                          ></ArrowForwardIcon>
                        </span>
                      </Button>
                    </CardActions>
                  </Card>
                </Item>
              </Grid>
            ))}
          </Grid>
        </div>

        <Box className="advertige_160">
          YOUR <br /> AD <br /> DISPLAY <br /> HERE <br /> 160 x 600
        </Box>
      </Box>
    </div>
  );
}

export function ProductMaterialCard2({ cardObject }) {
  const matches = useMediaQuery("(min-width:640px)");
  return (
    <div id="ProductMaterialCard">
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "row" : "column"}
      >
        <div
          className="product_wrapper"
          style={{ flexShrink: 50, width: "100%" }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          >
            {cardObject.map((v) => (
              <Grid item xs={12} md={6} lg={4} key={v.id}>
                <Item sx={{ boxShadow: 0 }}>
                  <Card
                    sx={{
                      maxWidth: "100%",
                      borderRadius: "15px",
                      overflow: "hidden",
                      boxShadow: "2px 2px 5px 1px #ddd",
                    }}
                  >
                    <CardActionArea component="div">
                      <Box height="220" position="relative" component="div">
                        <CardMedia
                          component="img"
                          height="220"
                          image={v.img}
                          alt="img"
                        />
                      </Box>
                    </CardActionArea>
                    <CardContent
                      sx={{ position: "relative", m: 0, my: 1, p: 0 }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        color="black"
                        sx={{ fontWeight: "bold", mb: 1 }}
                        align="center"
                      >
                        {v.title}
                      </Typography>

                      <Box className="content_info_box">
                        <span style={{ marginRight: "3px" }}>
                          CITY: <b>{v.city}</b>
                        </span>
                        <span>
                          POSTAL CODE: <b>{v.postal_code}</b>
                        </span>
                      </Box>
                    </CardContent>
                    <CardActions sx={{ px: 0, pb: 0 }}>
                      <Button
                        size="large"
                        sx={{ width: "100%", borderRadius: "15px" }}
                        className="btn_view_more"
                        component="a"
                        href={v.link}
                      >
                        View more{" "}
                        <ArrowForwardIcon
                          sx={{ marginLeft: 1 }}
                        ></ArrowForwardIcon>
                      </Button>
                    </CardActions>
                  </Card>
                </Item>
              </Grid>
            ))}
          </Grid>
        </div>

        <Box className="advertige_160">
          YOUR <br /> AD <br /> DISPLAY <br /> HERE <br /> 160 x 600
        </Box>
      </Box>
    </div>
  );
}

export function ProductMaterialCard3({ cardObject }) {
  const FavoriteIconHandle = (event) => {
    event.target.classList.toggle("favorite_active");
  };
  const matches = useMediaQuery("(min-width:640px)");

  return (
    <div id="ProductMaterialCard">
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "row" : "column"}
      >
        <div className="product_wrapper" style={{ flexShrink: 50 }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
            {cardObject.map((v) => (
              <Grid item xs={12} md={6} lg={4} key={v.id}>
                <Item sx={{ boxShadow: 0 }}>
                  <Card
                    sx={{
                      boxShadow: "2px 2px 5px 1px #ddd",
                      maxWidth: "100%",
                      borderRadius:
                        "var(--radius-lg) var(--radius-lg) 20px 20px",
                      overflow: "hidden",
                    }}
                  >
                    <CardActionArea
                      sx={{ pointerEvents: "none" }}
                      component="div"
                    >
                      <Box height="220" position="relative" component="div">
                        <CardMedia
                          component="img"
                          height="100%"
                          image={v.img}
                          alt="img"
                        />
                        <Button className="price_btn"> {v.price} </Button>
                      </Box>

                      <CardContent sx={{ position: "relative", m: 0, p: 0 }}>
                        <Typography
                          gutterBottom
                          variant="h5"
                          color="black"
                          sx={{ fontWeight: "bold", mb: 0 }}
                          align="center"
                        >
                          {v.title}
                        </Typography>
                        <FavoriteBorderIcon
                          onClick={FavoriteIconHandle}
                          sx={{
                            position: "absolute",
                            pointerEvents: "visible",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        ></FavoriteBorderIcon>
                      </CardContent>
                      <CardContent
                        sx={{ position: "relative", m: 0, px: 0, py: 1 }}
                      >
                        <Box className="content_info_box">
                          <span>
                            {" "}
                            <img
                              style={{ height: "12px" }}
                              src={require("../../static/icons/329Z.png")}
                              alt=""
                            />{" "}
                            Automatic
                          </span>
                          <span>
                            {" "}
                            <img
                              style={{ height: "12px" }}
                              src={require("../../static/icons/highway-icon.png")}
                              alt=""
                            />{" "}
                            {v.automatic}
                          </span>
                        </Box>
                        <Box className="content_info_box">
                          <span>Used</span>
                          <span>{v.used}</span>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ px: 0, pb: 0 }}>
                      <Button
                        size="large"
                        sx={{ width: "100%", borderRadius: 5 }}
                        className="btn_view_more"
                        href={v.link}
                      >
                        View more{" "}
                        <ArrowForwardIcon
                          sx={{ marginLeft: 1 }}
                        ></ArrowForwardIcon>
                      </Button>
                    </CardActions>
                  </Card>
                </Item>
              </Grid>
            ))}
          </Grid>
        </div>

        <Box className="advertige_160">
          YOUR <br /> AD <br /> DISPLAY <br /> HERE <br /> 160 x 600
        </Box>
      </Box>
    </div>
  );
}

export function ProductMaterialCard4({ cardObject }) {
  const FavoriteIconHandle = (event) => {
    event.target.classList.toggle("favorite_active");
  };
  const matches = useMediaQuery("(min-width:640px)");

  return (
    <div id="ProductMaterialCard">
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "row" : "column"}
      >
        <div className="product_wrapper" style={{ flexShrink: 50 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {cardObject.map((v) => (
              <Grid item xs={12} md={6} lg={4} key={v.id}>
                <Item sx={{ boxShadow: 0 }}>
                  <Card
                    sx={{
                      borderRadius: "20px",
                      overflow: "hidden",
                      boxShadow: "2px 2px 5px 1px #ddd",
                    }}
                  >
                    <CardActionArea component="div">
                      <Box height="220px" position="relative" component="div">
                        <CardMedia
                          component="img"
                          height="100%"
                          image={v.img}
                          alt="img"
                        />
                      </Box>
                    </CardActionArea>
                    <CardContent
                      sx={{ position: "relative", m: 0, my: 1, p: 0 }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        color="black"
                        sx={{ fontWeight: "bold", mb: 0 }}
                        align="center"
                      >
                        {v.title}
                      </Typography>
                      <FavoriteBorderIcon
                        onClick={FavoriteIconHandle}
                        sx={{
                          position: "absolute",
                          right: "30px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          fontSize: "35px",
                        }}
                      ></FavoriteBorderIcon>
                    </CardContent>
                    <CardContent
                      sx={{ position: "relative", m: 0, p: 1, px: 0 }}
                    >
                      <Box
                        className="cf_styles_content"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <LocationOnIcon
                          sx={{
                            color: "var(--auto-text-cyan)",
                            fontSize: "18px",
                          }}
                        ></LocationOnIcon>{" "}
                        <span>{v.location}</span>
                      </Box>
                    </CardContent>

                    <CardActions sx={{ px: 0, pb: 0 }}>
                      <Button
                        size="large"
                        sx={{ width: "100%", borderRadius: 5 }}
                        className="btn_view_more"
                        component="a"
                        href={v.link}
                      >
                        View more{" "}
                        <ArrowForwardIcon
                          sx={{ marginLeft: 1 }}
                        ></ArrowForwardIcon>
                      </Button>
                    </CardActions>
                  </Card>
                </Item>
              </Grid>
            ))}
          </Grid>
        </div>

        <Box className="advertige_160">
          YOUR <br /> AD <br /> DISPLAY <br /> HERE <br /> 160 x 600
        </Box>
      </Box>
    </div>
  );
}
