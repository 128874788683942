import React, { useEffect, useState } from "react";
import "./EditProfile.scss";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, Stack, useMediaQuery } from "@mui/material";
import PictureUploadProfile from "../../Components/PictureUploadProfile/PictureUploadProfile";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DatePicker from "react-datepicker";
import CountryDropdown from "country-dropdown-with-flags-for-react";

import PhoneInput from "react-phone-number-input";
import {
  changePasswordHttpRequest,
  createProfileHttpRequest,
  updateProfileHttpRequest,
  userDetailHttpRequest,
} from "../../static/http-content";
import moment from "moment";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));

function EditProfile(props) {
  const [userData, setUserData] = useState({
    first_name: null,
    last_name: null,
    phone: null,
    whatsapp_no: null,
    dob: null,
    country: null,
    state: null,
    road_no: null,
    house_no: null,
    biography: null,
  });

  const [password, setPassword] = useState({
    current_password: null,
    new_password: null,
    confirm_new_password: null,
  });

  const [email, setEmail] = useState(null);
  const [profile_pic, setProfile_pic] = useState({
    file: null,
    file_url: null,
  });
  const [profilePicLoading, setProfilePicLoading] = useState(false);
  const [profile_created, setProfile_created] = useState(false);
  const [refreshTokenApplied, setRefreshTokenApplied] = useState(false);

  useEffect(() => {
    console.log(localStorage.getItem("registertoken"));
    userDetailHttpRequest
      .get("/", {
        headers: { Authorization: `jwt ${localStorage.getItem("registertoken")}` },
      })
      .then((res) => {
        console.log(res?.data);
        setUserData({ ...res?.data?.data.profile_info });
        setEmail(res?.data?.data.user_info.email);
        setProfile_pic({
          ...profile_pic,
          file_url: res?.data?.data.profile_pic_url,
          file: null,
        });
        setProfile_created(res?.data?.data.user_info.is_profile);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response?.data.detail === "access_token expired") {
          localStorage.setItem("refresh_token", localStorage.getItem("refresh_token"));
          setRefreshTokenApplied(true);
        }
      });
  }, [refreshTokenApplied]);

  const updateProfile = (formData) => {
    console.log("Update Profile");
    for (var value of formData.values()) {
      console.log(value);
    }
    updateProfileHttpRequest
      .post("/", formData, {
        headers: { Authorization: `jwt ${localStorage.getItem("refresh_token")}` },
      })
      .then((res) => {
        alert(res?.data?.message);
      })
      .catch((err) => console.log(err.response));
  };

  const updateProfilePicture = (image) => {
    console.log("Update Profile Picture");
    console.log(image);

    let formData = new FormData();
    formData.append("profile_pic", image);
    formData.append("user_type", -1);
    setProfilePicLoading(true);
    updateProfileHttpRequest
      .post("/", formData, {
        headers: { Authorization: `jwt ${localStorage.getItem("refresh_token")}` },
      })
      .then((res) => {
        if (res?.data.statuscode === 200) {
          alert(res?.data.message);
        } else {
          alert(res?.data.message.profile_pic[0]);
        }
        setProfilePicLoading(false);
      })
      .catch((err) => console.log(err.response));
  };

  const createProfile = (formData) => {
    console.log("Create Profile");
    for (var value of formData.values()) {
      console.log(value);
    }
    createProfileHttpRequest
      .post("/", formData, {
        headers: { Authorization: `jwt ${localStorage.getItem("refresh_token")}` },
      })
      .then((res) => {
        alert(res?.data.message);
      })
      .catch((err) => console.log(err.response));
  };

  const submit = () => {
    console.log(userData, profile_pic);
    let formData = new FormData();
    formData.append("user_type", userData.user_type);
    formData.append("first_name", userData.first_name);
    formData.append("last_name", userData.last_name);
    formData.append("phone", userData.phone);
    formData.append("whatsapp_no", userData.whatsapp_no);
    formData.append("dob", userData.dob);
    formData.append("country", userData.country);
    formData.append("state", userData.state);
    formData.append("road_no", userData.road_no);
    formData.append("house_no", userData.house_no);
    formData.append("biography", userData.biography);

    if (profile_created) {
      updateProfile(formData);
    } else {
      formData.append("profile_pic", profile_pic);
      createProfile(formData);
    }
  };

  const submitPassword = () => {
    console.log(password);

    if (password.new_password === password.confirm_new_password) {
      let formData = new FormData();
      formData.append("current_password", password.current_password);
      formData.append("new_password", password.new_password);

      changePasswordHttpRequest
        .post("/", formData, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          console.log(res?.data);
          alert(res?.data.message);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      alert("Confirm password is wrong");
    }
  };

  return (
    <div id="EditProfile">
      <Box sx={{ pt: 6 }}>
        <h3 className="section_title_cyan max_size_cyan">Edit Profile</h3>

        <Box id="section1" component="section" sx={{ mt: 6 }}>
          <form action="" className="form_auth">
            <div className="content_wrapper">
              <FormBoxHead
                profile_pic={profile_pic}
                setProfile_pic={setProfile_pic}
                updateProfilePicture={updateProfilePicture}
                profilePicLoading={profilePicLoading}
                userData={userData}
                setUserData={setUserData}
              />
              <FormBox1
                userData={userData}
                setUserData={setUserData}
                email={email}
                setEmail={setEmail}
              />
              <FormSubmit submit={submit} />
              <FormBox2 password={password} setPassword={setPassword} />
              <FormSubmitPassword submitPassword={submitPassword} />
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default EditProfile;

const FormBoxHead = (props) => {
  const handleUploader = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      props.setProfile_pic({
        ...props.profile_pic,
        file: event.target.files[0],
        file_url: URL.createObjectURL(img),
      });

      props.updateProfilePicture(event.target.files[0]);
    }
  };

  return (
    <Box className="FormBoxHead">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 2, mt: 3 }}>
              <div className="form_group">
                <input
                  style={{ display: "none" }}
                  type="file"
                  className="form_control"
                  placeholder="Year of Registration of Vehicle..."
                  id="Vehicle_rc_input"
                  onChange={handleUploader}
                />

                <PictureUploadProfile
                  profilePicLoading={props.profilePicLoading}
                  profile_pic={props.profile_pic.file_url}
                />
              </div>
            </Box>

            <Stack className="edit_profile_tab">
              <TabBox
                userData={props.userData}
                setUserData={props.setUserData}
              ></TabBox>
            </Stack>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const TabBox = ({ userData, setUserData }) => {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    console.log(userData);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };
  return (
    <Box align="center" display="flex" justifyContent="center">
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        className="overlap_tabs"
      >
        <Tab
          disableRipple
          sx={{ flex: 1, textTransform: "none" }}
          label="Private"
        />
        <Tab
          disableRipple
          sx={{ flex: 1, textTransform: "none" }}
          label="Dealer"
        />
      </Tabs>
    </Box>
  );
};

const FormBox1 = (props) => {
  const matches = useMediaQuery("(min-width:640px)");

  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Profile Details</h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  First Name:{" "}
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="First Name..."
                  value={props?.userData?.first_name}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      first_name: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Last Name:{" "}
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Last Name..."
                  value={props?.userData?.last_name}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      last_name: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Email:
                </label>
                <input
                  type="email"
                  className="form_control"
                  placeholder="Email..."
                  disabled
                  value={props.email}
                  onChange={(e) => {
                    props.setEmail(e.target.value);
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Phone:
                </label>
                <div className="form_country_number_control">
                  <PhoneInput
                    international
                    defaultCountry="BD"
                    value={props?.userData?.phone}
                    onChange={(e) => {
                      props.setUserData({
                        ...props.userData,
                        phone: e,
                      });
                    }}
                  />
                </div>
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Biography
                </label>
                <textarea
                  rows="6"
                  placeholder="Describe yourself in altleast minimum 20 characters..."
                  className="form_control"
                  value={props?.userData?.biography}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      biography: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Date of Birth:
                </label>
                <HistoryDate
                  value={{
                    userData: props.userData,
                    setUserData: props.setUserData,
                  }}
                ></HistoryDate>
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  WhatsApp Number:
                </label>
                <div className="form_country_number_control">
                  <PhoneInput
                    international
                    defaultCountry="BD"
                    value={props?.userData?.whatsapp_no}
                    onChange={(e) => {
                      props.setUserData({
                        ...props.userData,
                        whatsapp_no: e,
                      });
                    }}
                  />
                </div>
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Road Name:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Road No..."
                  value={props?.userData?.road_no}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      road_no: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  House No:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="House No..."
                  value={props?.userData?.house_no}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      house_no: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Country:
                </label>

                <Box className="form_group form_country_group_full_width">
                  <CountryDropdown
                    style={{ padding: "0px" }}
                    className="form_control"
                    preferredCountries={["bd", "in"]}
                    value={props?.userData?.country}
                    handleChange={(e) => {
                      console.log(e.target.value);
                      props.setUserData({
                        ...props.userData,
                        country: e.target.value,
                      });
                    }}
                  ></CountryDropdown>
                </Box>
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  State:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="State..."
                  value={props?.userData?.state}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      state: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox2 = ({ password, setPassword }) => {
  const matches = useMediaQuery("(min-width:640px)");

  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">Change Password</h2>
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Current Password:
                </label>
                <input
                  type="password"
                  className="form_control"
                  placeholder="Current Password..."
                  value={password.current_password}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      current_password: e.target.value,
                    })
                  }
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  New Password:
                </label>
                <input
                  type="password"
                  className="form_control"
                  placeholder="New Password..."
                  value={password.new_password}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      new_password: e.target.value,
                    })
                  }
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  Confirm Password:
                </label>
                <input
                  type="password"
                  className="form_control"
                  placeholder="Confirm Password..."
                  value={password.confirm_new_password}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      confirm_new_password: e.target.value,
                    })
                  }
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormSubmit = (props) => {
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button sx={{ px: 6 }} className="btn_cyan_style" onClick={props.submit}>
        UPDATE PROFILE
      </Button>
    </Box>
  );
};

const FormSubmitPassword = (props) => {
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button
        sx={{ px: 6 }}
        className="btn_cyan_style"
        onClick={props.submitPassword}
      >
        UPDATE PASSWORD
      </Button>
    </Box>
  );
};

const HistoryDate = (props) => {
  useEffect(() => {
    console.log(new Date(props.value.userData.dob), props.value.userData);
  }, []);

  return (
    <DatePicker
      className="datePicker form_control"
      selected={new Date(props.value.userData.dob)}
      onChange={(date) =>
        props.value.setUserData({
          ...props.value.userData,
          dob: moment(date).format("DD-MM-YYYY"),
        })
      }
    />
  );
};
