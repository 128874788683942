import React, { useEffect, useState } from "react";
import "./SellCar1.scss";
import { Box, Button, useMediaQuery } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "../../static/css/style_helper/style_helper.scss";
import { useNavigate } from "react-router-dom";
import { getAllPackagesHttpRequest } from "../../static/http-content";
import moment from "moment";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function SellCar1(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const package_object = [
    {
      id: 1,
      title: "ATB15D",
      cost: "30 CHF",
      period: "1-15 DAY",
      images: "UP TO 7",
      per_car_cost: " UP TO 15 & 50 CHF",
      images_count: 1,
    },
    {
      id: 2,
      title: "ATB15D",
      cost: "30 CHF",
      period: "1-15 DAY",
      images: "UP TO 7",
      per_car_cost: " UP TO 15 & 50 CHF",
      images_count: 1,
    },
    {
      id: 3,
      title: "ATB15D",
      cost: "30 CHF",
      period: "1-15 DAY",
      images: "UP TO 7",
      per_car_cost: " UP TO 15 & 50 CHF",
      images_count: 1,
    },
    {
      id: 4,
      title: "ATB15D",
      cost: "30 CHF",
      period: "1-15 DAY",
      images: "UP TO 7",
      per_car_cost: " UP TO 15 & 50 CHF",
      images_count: 1,
    },
  ];
  const [packageData, setPackageData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllPackagesHttpRequest
      .get("/")
      .then((res) => {
        console.log(res.data.data);
        setPackageData(res.data.data);
      })
      .catch((err) => console.log(err.response));
  }, []);

  return (
    <div id="SellCar1">
      <Box sx={{ py: 6 }}>
        <div className="SellCar_header">
          <h3 className="cyan_style" style={{ width: matches ? "50%" : "90%" }}>
            Choose A Package
          </h3>
        </div>
        <Box id="section1" component="section" sx={{ mt: 6 }}>
          <Grid
            container
            rowSpacing={{ xs: 2, sm: 4, md: 6 }}
            columnSpacing={{ xs: 2, sm: 4, md: 6 }}
          >
            {packageData.map((v) => (
              <Grid item xs={12} md={6} key={v.id}>
                <Item sx={{ boxShadow: 3, borderRadius: 4 }}>
                  <Box>
                    <h2 className="title_line">{v.title}</h2>
                  </Box>
                  <Box>
                    <table className="package_table table_form">
                      <tbody>
                        <tr>
                          <td>PACKAGE COST</td>
                          <td>
                            <div className="cyan_value_box">{v.fees}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>START DATE</td>
                          <td>
                            <div className="cyan_value_box">
                              {moment(v.start_date).format("DD.MM.YYYY")}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>END DATE</td>
                          <td>
                            <div className="cyan_value_box">
                              {moment(v.end_date).format("DD.MM.YYYY")}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>NO OF VEHICLE</td>
                          <td>
                            <div className="cyan_value_box">
                              {v.package_specification[0]?.no_of_vehicle}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>IMAGES COUNT</td>
                          <td>
                            <div className="cyan_input_box">
                              <div className="form_group">
                                <select
                                  defaultValue="s"
                                  className="select_control"
                                >
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                </select>
                                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>

                  <Box sx={{ my: 3 }}>
                    <Button
                      sx={{ py: 0, px: 3 }}
                      className="btn_cyan_style"
                      onClick={() => navigate("/sell_car2", { state: v })}
                    >
                      TAKE PACKAGE
                    </Button>
                  </Box>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default SellCar1;
