const InitState = {
    language:""
  }
  
  const getLangaugeType = (state = InitState, action) => {
    
    switch (action.type) {
      case 'GET_LANGUAGE':
        return {
          ...state,
          language: action.payload
        }
  
      default:
        return state
    }
  }
  
  export default getLangaugeType;
  