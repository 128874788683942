import React from "react";

const LoginCardBlock = ({
  onClick,
  children,
}) => {
  return (
    <div
      onClick={onClick && onClick}
      className={`form_google_auth`}
    >
        {children}
    </div>
  );
};
// ${active
//   ? "border-primary bg-[#F2F7F7]"
//   : "shadow-login-card border-transparent"
//   } ${login && '!py-2.5 !px-2.5 rounded-md border !gap-3'} hover:border-primary hover:bg-[#F2F7F7] border-2 cursor-pointer transition-all ease-in rounded-2xl p-5 flex items-center lg:justify-start justify-center lg:mx-0 md:mx-auto mx-0 gap-5 ${width ? width : "md:w-[75%] w-full"
//   }

LoginCardBlock.defaultProps = {
  heading: 'Give "Heading" as a prop',
};

export default LoginCardBlock;
